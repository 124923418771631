import React from 'react';
import './CareHome.css'
import Dashboard from '../../Dashboard/Dashboard';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function CareHome() {
    return (
        <>
            <Dashboard />
            <div className="main-content care-home">
                <div className="container-fluid p-0 m-0">
                    <div className="home">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="welcome">
                                        <div className="container">
                                            <div className="wellcome-txt">
                                                <h6>Good Morning, Admin</h6>
                                                <p>Take a look at the latest update for your father</p>
                                            </div>
                                            <div className="welcome-image lady-doctor">
                                                <img src="/images/oldman.svg" alt="" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="resident mt-3">
                                            <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                                                <h5>Critical Patients</h5>
                                                <div className="left-content">
                                                    <div className="input-field position-relative">
                                                        <input type="text" className="form-control d-inline-block"
                                                            placeholder="Search Resident" /><span><img src='/images/search-icon.png' /></span>
                                                    </div>
                                                    <div className="filtericon">
                                                        <button>
                                                            <img src="/images/filter-icon.svg" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table-stripedd table table-bordered table-hover align-middle">
                                                    <thead className="table">
                                                        <tr>
                                                            <th scope="col">
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" /><span style={{ color: "#fff", fontWeight: "500", fontSize: "14px" }}>ID</span> <img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" />
                                                                </div>
                                                            </th>
                                                            <th scope="col">Name<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                                            <th scope="col">Status<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                                            <th scope="col">Condition<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>Ashok Kumar</td>
                                                            <td className="text-danger">Critical</td>
                                                            <td>High Bp</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr >
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Critical</td>
                                                            <td>High Cholesterol</td>
                                                            <td>
                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>


                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Critical</td>
                                                            <td>High Cholesterol</td>
                                                            <td>
                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>
                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr >
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Critical</td>
                                                            <td>High Cholesterol</td>
                                                            <td>
                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>
                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Critical</td>
                                                            <td>High Bp</td>
                                                            <td>
                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>
                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr >
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Critical</td>
                                                            <td>High Cholesterol</td>
                                                            <td>
                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>
                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>

                                                        {/* <!-- Add more rows as needed --> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="resident mt-3">
                                            <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                                                <h5>Staff Information</h5>
                                                <div className="left-content">
                                                    <div className="input-field position-relative">
                                                        <input type="text" className="form-control d-inline-block"
                                                            placeholder="Search Resident" /><span><img src='/images/search-icon.png' /></span>
                                                    </div>
                                                    <div className="filtericon">
                                                        <button>
                                                            <img src="/images/filter-icon.svg" alt="" />
                                                        </button>
                                                    </div>
                                                    <div className='btn-resident'>
                                                        <button className="btn btn-primary"><i className="fa-light fa-plus"></i>
                                                            <span className='d-block'>Add</span><span className='d-block'>Staff</span></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table-stripedd table table-bordered table-hover align-middle">
                                                    <thead className="table">
                                                        <tr>
                                                            <th scope="col">
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" /><span style={{ color: "#fff", fontWeight: "500", fontSize: "14px" }}>ID</span> <img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" />
                                                                </div>
                                                            </th>
                                                            <th scope="col">Name<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                                            <th scope="col">Status<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                                            <th scope="col">Shift Time<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                                            <th scope="col">Contact<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>Ashok Kumar</td>
                                                            <td className="text-success">Present</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Absent</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-success">Present</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Absent</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-success">Present</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Absent</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-success">Present</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="checkbox checbox-trans">
                                                                    <input className="form-check-input" type="checkbox" />P-002
                                                                </div>
                                                            </td>
                                                            <td>John doe</td>
                                                            <td className="text-danger">Absent</td>
                                                            <td>Morning</td>
                                                            <td>8444393432</td>
                                                            <td>

                                                                <button className="btn btn-sm "><img src="/images/eye.svg" alt="" /></button>

                                                                <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src="/images/edit-2.svg" alt="" />
                                                                </button>
                                                                <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                    <li className='borderbd mx-2'><a class="dropdown-item" href="#">Recovered</a></li>
                                                                    <li className='mx-2'><a className="dropdown-item" href="#">In Treatment</a></li>

                                                                </ul>
                                                                <button className="btn btn-sm">
                                                                    <img src="/images/trash.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>


                                                        {/* <!-- Add more rows as needed --> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="calendar ds-cd">
                                        <Calendar />
                                    </div>
                                    <div className="alerts-container">
                                        <div className="alerts-header">
                                            <h3>Critical Alerts</h3>
                                            <a href="#" className="view-all">View all</a>
                                        </div>

                                        <div className="alert-item">
                                            <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture"
                                                className="profile-pic" />
                                            <div className="alert-info">
                                                <h4>John doe</h4>
                                                <p>Not able to walk properly since morning</p>
                                            </div>
                                        </div>

                                        <div className="alert-item">
                                            <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture"
                                                className="profile-pic" />
                                            <div className="alert-info">
                                                <h4>Marie</h4>
                                                <p>Not able to walk properly since morning</p>
                                            </div>
                                        </div>

                                        <div className="attention-required">
                                            <span className="attention-icon"><i
                                                className="fa-solid fa-hexagon-exclamation"></i></span>
                                            <span>Attention Required</span>
                                        </div>
                                    </div>
                                    <div className="messages-container mt-3">
                                        <div className="header">
                                            <h2>Messages</h2>
                                            <a href="#" className="new-message"><i className="fa-regular fa-plus"></i>
                                                New
                                                message</a>
                                        </div>
                                        <div className="search-bar">
                                            <input type="text" placeholder="Search" />
                                            <spam><img src='/images/search-icon.png' alt=''/></spam>
                                        </div>
                                        <div className="message-list">
                                            <div className="message-item">
                                                <img src="/images/Critical-Alerts-profile.svg"
                                                    alt="Profile Picture" />
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <span className="name">High Cholesterol</span>
                                                        <span className="time">11:30 am</span>
                                                    </div>
                                                    <p>Medicines are changed as per prescriptions</p>
                                                </div>
                                            </div>
                                            <div className="message-item">
                                                <img src="/images/profile-image2.svg" alt="Profile Picture" />
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <span className="name">Mira</span>
                                                        <span className="time">11:30 am</span>
                                                    </div>
                                                    <p>Medicines are changed as per prescriptions</p>
                                                </div>
                                            </div>
                                            <div className="message-item">
                                                <img src="/images/Critical-Alerts-profile.svg"
                                                    alt="Profile Picture" />
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <span className="name">John Doe</span>
                                                        <span className="time">11:30 am</span>
                                                    </div>
                                                    <p>Medicines are changed as per prescriptions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="view-all">View All</button>
                                    </div>

                                    <div className="messages-container mt-3">
                                        <div className="header header-tow">
                                            <div>
                                                <h2>Today’s Appointments</h2>
                                                <p>Your todays appointments</p>
                                            </div>
                                            <a href="#" className="new-message"> View All</a>
                                        </div>
                                        <div className="message-list">
                                            <div className="message-item">
                                                <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture" />
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <span className="name">John doe</span>
                                                        <span className="time">11:30 am


                                                            <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                                                            </button>
                                                            <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                <li className='borderbd mx-2'><a class="dropdown-item" href="#">Assign caretaker</a></li>
                                                                <li className='mx-2'><a className="dropdown-item" href="#">View details</a></li>

                                                            </ul>
                                                        </span>
                                                    </div>
                                                    <p>New Patient</p>
                                                </div>
                                            </div>
                                            <div className="message-item">
                                                <img src="/images/profile-image2.svg" alt="Profile Picture" />
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <span className="name">Mira</span>
                                                        <span className="time">11:30 am
                                                            <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                                                            </button>
                                                            <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                <li className='borderbd mx-2'><a class="dropdown-item" href="#">Assign caretaker</a></li>
                                                                <li className='mx-2'><a className="dropdown-item" href="#">View details</a></li>

                                                            </ul>
                                                        </span>
                                                    </div>
                                                    <p>Weekly visit</p>
                                                </div>
                                            </div>
                                            <div className="message-item">
                                                <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture" />
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <span className="name">John Doe</span>
                                                        <span className="time">11:30 am
                                                            <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                                                            </button>
                                                            <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                <li className='borderbd mx-2'><a class="dropdown-item" href="#">Assign caretaker</a></li>
                                                                <li className='mx-2'><a className="dropdown-item" href="#">View details</a></li>

                                                            </ul>
                                                        </span>
                                                    </div>
                                                    <p>Consultation</p>
                                                </div>
                                            </div>
                                            <div className="message-item">
                                                <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture" />
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <span className="name">John Doe</span>
                                                        <span className="time">11:30 am
                                                            <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                                                            </button>
                                                            <ul className="edit-rt dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                                                                <li className='borderbd mx-2'><a class="dropdown-item" href="#">Assign caretaker</a></li>
                                                                <li className='mx-2'><a className="dropdown-item" href="#">View details</a></li>

                                                            </ul>
                                                        </span>
                                                    </div>
                                                    <p>Consultation</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
