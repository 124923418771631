import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './PatientInvoice.css';

export default function PatientInvoice() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="" className='btn btn-sm' onClick={handleShow}>
          <img src="/images/eye.svg" alt="" />
        </Button>
  
        <Modal show={show} onHide={handleClose} className='patient-invoice-popup'>
          <Modal.Header closeButton>
            <Modal.Title>Patient Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="patient-invoice-popup-body">
              <div className='patient-invoice-card'>
                  <div className='icon-name'>
                      <div className='position-relative'>
                          <img src='/images/pateint-details-icon.svg' alt='pateint-details-icon' />
                          <img className='online-dot' src='/images/online-dot.svg' alt='online' />
                      </div>
                      <div className='pateint-name-popup'>
                          <h5>Ashok Kumar</h5>
                          <p>Patient</p>
                      </div>
                  </div>
                  <div className='patient-invoice-info gap-3 d-sm-flex justify-content-between'>
                  <div className='Patient-more'>
                      <div>
                          <p>Contact :</p>
                          <p>+91-12345678910</p>
                      </div>
                      <div>
                          <p>Email :</p>
                          <p>Johndoe@gmail.com</p>
                      </div>
                      <div>
                          <p>Gender :</p>
                          <p>Male</p>
                      </div>
                      <div>
                          <p>Address:</p>
                          <p>714 Burwell Heights Road, Bridge City, TX, 77611</p>
                      </div>
                  </div>
                  <div className='Patient-more mt-2 mt-sm-0'>
                      <div>
                          <p>Invoice no :</p>
                          <p>#5467</p>
                      </div>
                      <div>
                          <p>Issue Date:</p>
                          <p>01/Sep/2024</p>
                      </div>
                      <div>
                          <p>Issue Date:</p>
                          <p>11/Dec/2024</p>
                      </div>
                      <div>
                          <p>Dr.Name:</p>
                          <p>Dr.Radhika</p>
                      </div>
                  </div>
                  </div>
              </div>
              <div className='popup-report-box mt-4'>
              <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                          <div>
                          <div className='d-flex gap-1 align-items-center'>
                          <h5 className='mb-0'>Invoice</h5><p className='pi-paid d-noney'>Paid</p><p className='pi-unpaid'>Unpaid</p>
                          </div>
                          <span className='surgery-info'>
                          Surgery (Gynaecology)
                          </span>
                          </div>
                          
                          <div className="left-content">
                              <div className='btn-resident'>
                                  <button  className="btn btn-primary"><img src='/images/notification.svg' alt=""/>
                                      <span className='d-block'>Remind</span></button>
                              </div>
                          </div>
                      </div>
                  <div className="resident mt-3 border-bottom">
                      <div className="table-responsive">
                          <table className="table-stripedd table table-bordered table-hover align-middle">
                              <thead className="table">
                                  <tr>
                                  <th scope="col">No.<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                      <th scope="col">Item<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                      <th scope="col">Qty<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                      <th scope="col">Rate<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                      <th scope="col">Total</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                     <td>1</td>
                                      <td>Hospital Charges</td>
                                      <td>1</td>
                                      <td>₹2000.00</td>
                                      <td>₹2000.00</td>
                                  </tr>
                                  <tr style={{ backgroundcolor: "#F6F6F6" }}>
                                      <td>2</td>
                                      <td>John doe</td>
                                      <td>1</td>
                                      <td>₹2500.00</td>
                                      <td>₹2500.00</td>
                                  </tr>
  
                                  {/* <!-- Add more rows as needed --> */}
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div className=' d-flex justify-content-end align-items-center mt-3 mb-4'>
                  <div className='Patient-more mt-2 mt-sm-0'>
                      <div>
                          <p>Subtotal:</p>
                          <p className='text-end'>₹4500.00</p>
                      </div>
                      <div>
                          <p>Taxes:</p>
                          <p className='text-end'>0</p>
                      </div>
                      <div>
                          <p className='fw-bold final-total'>Total:</p>
                          <p className='text-end fw-bold'>₹4500.00</p>
                      </div>
                  </div>
                  </div>
              </div>
          </div>
          </Modal.Body>
        </Modal>
      </>
    );
}
