import React from 'react';
import './login.css';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";

export default function Login(){
    const navigate = useNavigate();
    return(
    <div className="sign-up">
        <div className="container-lg">
            <div className="row">
                <div className="col-md-6">
                    {/* <div className="sign-up-image">
                        <div className="sign-up-overlay">
                            <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div> */}
                    <div className='left-img position-relative'>
                        <img src={LoginImg} alt='left img'/>
                        <div className='left-img-text'>
                        <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="sign-up-section">
                        <div className="sign-up-wellcome">
                            <img src={Logo} alt="Logo"/>
                            <h3>Login</h3>
                            <p>Enter your details to login</p>
                        </div>
                        <div className="sign-up-field">
                           <form>
                           <div className="input-field">
                                <label for="">Email</label>
                                <input type="text" placeholder="Value"/>
                            </div>
                            <div className="input-field">
                                <label for="">Password</label>
                                <input type="Password" placeholder="Value"/>
                            </div> 
                           </form>
                           <div className="input-field mt-3">
                                <button type="submit" onClick={() => navigate('/Doctor')}>Login</button>
                            </div> 
                            <span>Don’t have an account?<a href="#"> Sign Up</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}