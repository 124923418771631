import React from 'react'

export default function OfficeUseOnly() {
  return (
    <>
      <div
        className="tab-pane fade"
        id="office-use-only"
        role="tabpanel"
        aria-labelledby="office-use-only-tab"
      >
        <h5>For Office use Only</h5>
        <div className="forms ps-0 officeuse-box">
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Resident/Guardian Signature:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" />
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Date of Admission:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" />
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Room Number:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" />
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Assigned Care Coordinator:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" />
                </div>
            </div>
          <div className="d-sm-flex gap-3 align-items-center">
            <div>
              <label className="form-check-label" for="Nap-Habits">
                <ul className="mb-2 mt-3 mt-sm-0">
                  <li>
                  Care Level Required:
                  </li>
                </ul>
              </label>
            </div>
            <div className="options mt-sm-0 mt-4 mt-sm-0">
              <label>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  name="flu"
                  value="yes"
                />
                Independent
              </label>
              <label>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  name="flu"
                  value="yes"
                />
                Assisted Living
              </label>
              <label>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  name="flu"
                  value="no"
                />
                Memory Care
              </label>
            </div>
          </div>
          <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Special Care Instructions:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" />
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Additional Notes:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" />
                </div>
            </div>
          <button type="submit">Save</button>
        </div>
      </div>
    </>
  )
}
