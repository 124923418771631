import React from 'react';
import './AddInvoice.css';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function AddInvoice() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
      <Button className="btn btn-primary" onClick={handleShow}><i className="fa-light fa-plus"></i>
      <span className='d-block'>Create</span><span className='d-block'>Invoice</span>
      </Button>
        
  
        <Modal show={show} onHide={handleClose} className='add-bill-popup'>
          <Modal.Header closeButton>
            <Modal.Title>Add Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='add-bill-box'>
                <form>
                <div className='row gx-3'>
                    <div className='col-sm-6'>
                        <div className='add-bill-fields'>
                        <div class="mb-3">
                            <label for="Billno" class="form-label">Bill no*</label>
                            <input class="form-control" type="text" id='BillNo' placeholder="1234567" aria-label="Disabled input example" disabled/>
                        </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='add-bill-fields'>
                        <div class="mb-3">
                            <label for="AdmissionId" class="form-label">Admission Id*</label>
                            <input type="text" class="form-control" id="AdmissionId" placeholder="Value"/>
                        </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='add-bill-fields'>
                        <div class="mb-3">
                            <label for="PatientName" class="form-label">Patient Name</label>
                            <input type="text" class="form-control" id="PatientName" placeholder="Value"/>
                        </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='add-bill-fields'>
                        <div class="mb-3">
                            <label for="DoctorName" class="form-label">Doctor Name</label>
                            <input type="text" class="form-control" id="DoctorName" placeholder="Value"/>
                        </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='add-bill-fields'>
                        <div class="mb-3">
                            <label for="AdmitDate" class="form-label">Admit Date</label>
                            <input type="date" class="form-control datepicker" id="AdmitDate" placeholder="Value"/>
                        </div>
                        </div>
                    </div>

                    <div className='col-sm-6'>
                        <div className='add-bill-fields'>
                        <div class="mb-3">
                            <label for="Discharge" class="form-label">Discharge Date (If Discharged)</label>
                            <input type="date" class="form-control datepicker" id="Discharge" placeholder="Value"/>
                        </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='add-bill-fields'>
                        <div class="mb-3">
                            <label for="TotalAmount" class="form-label">Total Amount</label>
                            <input type="text" class="form-control" id="TotalAmount" placeholder="Value"/>
                        </div>
                        </div>
                    </div>
                    <div className='add-bill-btn'>
                        <div><button className='save-btn' type="submit">Save</button></div>
                        <div><button className='cancel-btn' type="submit">Cancel</button></div>
                    </div>
                </div>
                </form>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
}