import React from 'react';
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import './PatientHealthslider.css'

export default function PatientHealthslider() {
  return (
    <>
     <div className="slider-card">
              <Swiper
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                347: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={2}
                navigation
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <div className="silde-card">
                    <div className="slide-card-box">
                      <img src="/images/blood-pressure.svg" alt="blood-pressure"/>
                      <h4>Blood Pressure</h4>
                      <p>180/120<span>mmHg</span></p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="silde-card">
                    <div className="slide-card-box">
                      <img src="/images/blood-temprature.svg" alt="blood-temprature"/>
                      <h4>Blood Temprature</h4>
                      <p>37.5<span>C</span></p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="silde-card">
                    <div className="slide-card-box">
                      <img src="/images/glucose-m.svg" alt="glucose"/>
                      <h4>Glucose Level</h4>
                      <p>70-90</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="silde-card">
                    <div className="slide-card-box">
                      <img src="/images/blood.svg" alt="Heart-Rate"/>
                      <h4>Heart Rate</h4>
                      <p>140<span>BPM</span></p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="silde-card">
                    <div className="slide-card-box">
                      <img src="/images/bmi.svg" alt="bmi"/>
                      <h4>BMI</h4>
                      <p>64.1<span>kg</span></p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="silde-card">
                    <div className="slide-card-box">
                      <img src="/images/blood-pressure.svg" alt="blood-pressure"/>
                      <h4>Blood Pressure</h4>
                      <p>180/120<span>mmHg</span></p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
    </>
  )
}
