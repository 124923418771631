import React from 'react';
import './dashboard.css'

export default function Dashboard() {
  return (
    <>
    <div className="dashboard">
        <div className="dashboard-two">
            <div className="top-bar">
                <div className="top-bar-inner">
                    <div className="mobile-menu d-lg-none">
                        <div className="aside-bar">
                            <div className="aside-bar-menu">
                                <nav className="navbar navbar-expand-lg">
                                    <div className="">
                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                            aria-label="Toggle navigation">
                                            <i className="fa-solid fa-bars"></i>
                                        </button>


                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <div className="logo">
                                                <a className="navbar-brand" href="#">
                                                    <img src="/images/endwelllcare-logo.png" alt="Logo"/>
                                                </a>
                                            </div>
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                                <li className="nav-item active">
                                                    <a className="nav-link active" aria-current="page" href="#">
                                                        <img src="/images/home-2.svg" alt="" className="white-icon"/>
                                                        <img src="/images/home.svg" alt="" className="color-icon"/>
                                                        Home</a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/health-monitoring-2.svg" alt=""
                                                            className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span> Health Monitoring</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link d-flex gap-1" href="#">
                                                        <div>
                                                        <img src="/images/settings-gear-2.svg" alt="" className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        </div>
                                                        <span className='d-block'>Medication Management</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/page-search-lines-2.svg" alt=""
                                                            className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span> Activity tracker</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/file-text-2.svg" alt="" className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span>Incident Reports</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/book-2.svg" alt="" className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span>Communication Log</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/key-2.svg" alt="" className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span>Care Plan Access</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/trending-2.svg" alt="" className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span> Reports & Analytics</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/receipt-bill-2.svg" alt="" className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span> Billing</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item logout-button">
                                                    <a className="nav-link" href="#">
                                                        <img src="/images/logout.svg" alt="" className="white-icon"/>
                                                        <img src="" alt="" className="color-icon"/>
                                                        <span> Logout</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="top-bar-search">
                        <div className="sarch-bar position-relative">
                            <input type="search" placeholder="Search "/>
                            <img src='/images/search-normal.png'/>
                        </div>
                        <div className="bell-icon">
                            <i className="fa-regular fa-bell"></i>
                            <span></span>
                        </div>
                        <div className="login-profile">
                            <div className="Profile-image">
                                <img src="/images/profile-image.jpeg" alt=""/>
                            </div>
                            <div className="profile-details">
                                <p>Dr. Radhika </p>
                                <span>Doctor</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="tab-menu">
        <div className="aside-bar" onclick="openNav()">
            <div className="aside-bar-menu">
                <nav className="navbar navbar-expand-lg">
                    <div className="">
                        <div className="logo">
                            <a className="navbar-brand" href="#">
                                <img src="/images/endwelllcare-logo.png" alt=""/>
                            </a>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item active">
                                    <a className="nav-link active" aria-current="page" href="#">
                                        <img src="/images/home-2.svg" alt="" className="white-icon"/>
                                        <img src="/images/home.svg" alt="" className="color-icon"/>
                                        Home</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="#">
                                        <img src="/images/health-monitoring-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span> Health Monitoring</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                  <a className="nav-link d-flex gap-1" href="#">
                                    <div>
                                        <img src="/images/settings-gear-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                    </div>
                                      <span className='d-block'>Medication Management</span>
                                  </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="#">
                                        <img src="/images/page-search-lines-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span> Activity tracker</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="#">
                                        <img src="/images/file-text-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span>Incident Reports</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="#">
                                        <img src="/images/book-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span>Communication Log</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="#">
                                        <img src="/images/key-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span>Care Plan Access</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="#">
                                        <img src="/images/trending-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span> Reports & Analytics</span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="#">
                                        <img src="/images/receipt-bill-2.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span> Billing</span>
                                    </a>
                                </li>
                                <li className="nav-item logout-button">
                                    <a className="nav-link" href="#">
                                        <img src="/images/logout.svg" alt="" className="white-icon"/>
                                        <img src="" alt="" className="color-icon"/>
                                        <span> Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="aside-toggle">
                    <i className="fa-solid fa-angle-left"></i>
                </div>
            </div>
        </div>
    </div>
        
    </>
  )
}
