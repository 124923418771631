import React from "react";

export default function ConsentSignatures() {
  return (
    <>
      <div
        className="tab-pane fade"
        id="Consent-signatures"
        role="tabpanel"
        aria-labelledby="Consent-signatures-tab"
      >
        <h5>Consent and Signatures</h5>
        <div className="forms ps-0 consent-box">
          <div className="d-sm-flex gap-3 align-items-center">
            <div>
              <label className="form-check-label ms-1" for="Nap-Habits">
                <ul className="mb-2">
                  <li>
                    Consent to Share Medical Information with Authorized
                    Personnel:
                  </li>
                </ul>
              </label>
            </div>
            <div className="options mt-sm-0 mt-3">
              <label>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  name="flu"
                  value="yes"
                />
                Yes
              </label>
              <label>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  name="flu"
                  value="no"
                />
                No
              </label>
            </div>
          </div>
          <div className="d-sm-flex gap-3 align-items-center">
            <div>
              <label className="form-check-label ms-1" for="Nap-Habits">
                <ul className="mb-2">
                  <li>Consent to Participate in Group Activities:</li>
                </ul>
              </label>
            </div>
            <div className="options mt-sm-0 mt-3">
              <label>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  name="flu"
                  value="yes"
                />
                Yes
              </label>
              <label>
                <input
                  className="form-check-input me-1"
                  type="checkbox"
                  name="flu"
                  value="no"
                />
                No
              </label>
            </div>
          </div>
          <label
            className="form-check-label ms-1 mt-3 mt-sm-0"
            for="Nap-Habits"
          >
            <ul className="mb-2">
              <li>
                I understand that the information in this form is accurate and
                will be used to provide the best care for the resident.
              </li>
            </ul>
          </label>
          <div className="mt-3 d-md-flex">
            <div className="">
              <p>Resident/Guardian Signature:</p>
            </div>
            <div className="last-dose w-30">
              <input type="text" className="border-bottom-5 mt-3 mt-sm-0" />
            </div>
          </div>
          <div className="mt-3 d-md-flex">
            <div className="mt-5 mt-sm-0">
              <p>Date:</p>
            </div>
            <div className="last-dose w-30">
              <input type="text" className="border-bottom-5 mt-3 mt-sm-0" />
            </div>
          </div>
          <button type="submit">Save & Continue</button>
        </div>
      </div>
    </>
  );
}
