import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './PateintDetails.css'

export default function PateintDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="" className='btn btn-sm' onClick={handleShow}>
        <img src="/images/eye.svg" alt="" />
      </Button>

      <Modal show={show} onHide={handleClose} className='pateint-details-popup'>
        <Modal.Header closeButton>
          <Modal.Title>Pateint Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="pateint-details-popup-body">
            <div className='pateint-details-card'>
                <div className='icon-name'>
                    <div className='position-relative'>
                        <img src='/images/pateint-details-icon.svg' alt='pateint-details-icon' />
                        <img className='online-dot' src='/images/online-dot.svg' alt='online' />
                    </div>
                    <div className='pateint-name-popup'>
                        <h5>Ashok Kumar</h5>
                        <p>Patient</p>
                    </div>
                </div>
                <div className='Patient-more'>
                    <div>
                        <p>Contact :</p>
                        <p>+91-12345678910</p>
                    </div>
                    <div>
                        <p>Email :</p>
                        <p>Johndoe@gmail.com</p>
                    </div>
                    <div>
                        <p>Gender :</p>
                        <p>Male</p>
                    </div>
                    <div>
                        <p>Address:</p>
                        <p>714 Burwell Heights Road, Bridge City, TX, 77611</p>
                    </div>
                </div>
            </div>
            <div className='popup-report-box'>
                <div className="resident mt-3">
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                        <h5>Reports</h5>
                        <div className="left-content">
                            <div className="input-field position-relative">
                                <input type="text" className="form-control d-inline-block"
                                    placeholder="Search Reports" /><span><img src='/images/search-icon.png' /></span>
                            </div>
                            <div className="filtericon">
                                <button>
                                    <img src="/images/filter-icon.svg" alt="" />
                                </button>
                            </div>
                            <div className='btn-resident'>
                                <button  className="btn btn-primary"><i className="fa-light fa-plus"></i>
                                    <span className='d-block'>Add</span><span className='d-block'>Report</span></button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table-stripedd table table-bordered table-hover align-middle">
                            <thead className="table">
                                <tr>
                                    <th scope="col">
                                        <div className="checkbox checbox-trans">
                                            <input className="form-check-input" type="checkbox" /><span style={{ color: "#fff", fontWeight: "500", fontSize: "14px" }}>Report</span> <img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" />
                                        </div>
                                    </th>
                                    <th scope="col">Created Date<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Time<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Care Taker<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="checkbox checbox-trans">
                                            <input className="form-check-input" type="checkbox" />Reports 3 glucose level complete report
                                        </div>
                                    </td>
                                    <td>26 June 2024</td>
                                    <td>08:30 pm</td>
                                    <td>Rahul Jain</td>
                                    <td className='table-fix'>
                                        <button className="btn btn-sm ">
                                            <img src="/images/eye.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/edit-2.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/trash.svg" alt="" />
                                        </button>
                                    </td>
                                </tr>
                                <tr style={{ backgroundcolor: "#F6F6F6" }}>
                                    <td>
                                        <div className="checkbox checbox-trans">
                                            <input className="form-check-input" type="checkbox" />Reports 3 glucose level complete report
                                        </div>
                                    </td>
                                    <td>26 June 2024</td>
                                    <td>08:30 pm</td>
                                    <td>Rahul Jain</td>
                                    <td className='table-fix'>
                                        <button className="btn btn-sm ">
                                            <img src="/images/eye.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/edit-2.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/trash.svg" alt="" />
                                        </button>
                                    </td>
                                </tr>

                                {/* <!-- Add more rows as needed --> */}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="resident mt-3">
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                        <h5>Prescribed Medicines</h5>
                        <div className="left-content">
                            <div className="input-field position-relative">
                                <input type="text" className="form-control d-inline-block"
                                    placeholder="Search Prescriptions" /><span><img src='/images/search-icon.png' /></span>
                            </div>
                            <div className="filtericon">
                                <button>
                                    <img src="/images/filter-icon.svg" alt="" />
                                </button>
                            </div>
                            <div className='btn-resident'>
                                <button className="btn btn-primary"><i className="fa-light fa-plus"></i>
                                    <span className='d-block'>Add</span><span className='d-block'>Medications</span></button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table-stripedd table table-bordered table-hover align-middle">
                            <thead className="table">
                                <tr>
                                    <th scope="col">
                                        <div className="checkbox checbox-trans">
                                            <input className="form-check-input" type="checkbox" /><span style={{ color: "#fff", fontWeight: "500", fontSize: "14px" }}>Medicines</span> <img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" />
                                        </div>
                                    </th>
                                    <th scope="col">Date<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Medication Schedule<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="checkbox checbox-trans">
                                            <input className="form-check-input" type="checkbox" />Paracetamol
                                        </div>
                                    </td>
                                    <td>26/06/2024</td>
                                    <td>2 times a day</td>
                                    <td className='table-fix'>
                                        <button className="btn btn-sm ">
                                            <img src="/images/eye.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/edit-2.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/trash.svg" alt="" />
                                        </button>
                                    </td>
                                </tr>
                                <tr style={{ backgroundcolor: "#F6F6F6" }}>
                                    <td>
                                        <div className="checkbox checbox-trans">
                                            <input className="form-check-input" type="checkbox" />Reports 3 glucose level complete report
                                        </div>
                                    </td>
                                    <td>26 June 2024</td>
                                    <td>2 times a day</td>
                                    <td className='table-fix'>
                                        <button className="btn btn-sm ">
                                            <img src="/images/eye.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/edit-2.svg" alt="" />
                                        </button>
                                        <button className="btn btn-sm">
                                            <img src="/images/trash.svg" alt="" />
                                        </button>
                                    </td>
                                </tr>

                                {/* <!-- Add more rows as needed --> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
