import React from "react";
import "./Invoice.css";
import Dashboard from "../../Dashboard/Dashboard";
import AddInvoice from "./AddInvoicemodal/AddInvoice";
import PatientInvoice from "../../Modals/PatientInvoice/PatientInvoice";

export default function Invoice() {
  return (
    <>
      <Dashboard />
      <div className="main-content invoice-box">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="row">
              <div className="col-md-12">
                <div className="resident">
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                    <h5>Invoice List</h5>
                    <div className="left-content">
                      <div className="input-field position-relative">
                        <input
                          type="text"
                          className="form-control d-inline-block"
                          placeholder="Search Resident"
                        />
                        <span>
                          <img src="/images/search-icon.png" />
                        </span>
                      </div>
                      <div className="filtericon">
                        <button>
                          <img src="/images/filter-icon.svg" alt="" />
                        </button>
                      </div>
                      <div className="btn-resident">
                        <AddInvoice />
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table-stripedd table table-bordered table-hover align-middle">
                      <thead className="table">
                        <tr>
                          <th scope="col">
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                Invoice
                              </span>{" "}
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </div>
                          </th>
                          <th scope="col">
                            Name
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Contact
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Amount
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Generate Date
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Status
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="paid">paid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td>8444393432</td>
                          <td>₹2000.00</td>
                          <td>26/06/2024</td>
                          <td>
                            <span className="unpaid">Unpaid</span>
                          </td>
                          <td className="table-fix">
                            <PatientInvoice />

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        {/* <!-- Add more rows as needed --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
