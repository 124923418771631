import React from 'react';
import './Otp.css';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";

export default function FamilyOTP() {
    const navigate = useNavigate();
  return (
    <div className="sign-up otp-box">
        <div className='container-lg'>
        <div className="row">
        <div className="col-md-6">
                    {/* <div className="sign-up-image">
                        <div className="sign-up-overlay">
                            <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div> */}
                    <div className='left-img position-relative'>
                        <img src={LoginImg} alt='left img'/>
                        <div className='left-img-text'>
                        <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div>
                </div>
            <div className="col-md-6">
                <div className="verify-otp-section">
                    <div className="sign-up-wellcome">
                        <img src={Logo}  alt=""/>
                        <h3>Verify OTP</h3>
                        <p>Enter the verification code sent to your email id</p>
                    </div>
                    <div className="verify-otp">
                        <div className="input-field">
                            <label for="">Verify OTP*</label>
                            <div className='opt-inputs mt-3'>
                            <input type="text" placeholder="."/>
                            <input type="text" placeholder="."/>
                            <input type="text" placeholder="."/>
                            <input type="text" placeholder="."/>
                            <input type="text" placeholder="."/>
                            <input type="text" placeholder="."/>
                            </div>
                        </div>
                        <div className='wrong-password d-none'><img src='/images/circle-info.svg'/><span className='d-block'>Wrong code, Please try again</span></div>
                        <div className="input-field mt-3">
                            <button type="submit" onClick={() => navigate('/family/home')}>Send OTP</button>
                        </div>
                        <span className='send-txt'>Didn’t get your code? <a href="#">Send a new code</a></span>
                    </div>
                </div>
            </div>
        </div>  
        </div>
    </div>
  )
}
