import React from "react";
import "./StaffDetails.css";
import Dashboard from "../Dashboard/Dashboard";

export default function StaffDetails() {
  return (
    <>
      <Dashboard />
      <div className="main-content staff-details-main">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="row">
              <div className="col-md-12">
                <div className="resident">
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                    <h5>Staff Details</h5>
                    <div className="left-content">
                      <div className="input-field position-relative">
                        <input
                          type="text"
                          className="form-control d-inline-block"
                          placeholder="Search Staff"
                        />
                        <span>
                          <img src="/images/search-icon.png" />
                        </span>
                      </div>
                      <div className="filtericon">
                        <button>
                          <img src="/images/filter-icon.svg" alt="" />
                        </button>
                      </div>
                      <div className="btn-resident">
                        <button className="btn btn-primary">
                          <i className="fa-light fa-plus"></i>
                          <span className="d-block">Add</span>
                          <span className="d-block">New</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table-stripedd table table-bordered table-hover align-middle">
                      <thead className="table">
                        <tr>
                          <th scope="col">
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                ID
                              </span>{" "}
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </div>
                          </th>
                          <th scope="col">
                            Name
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Designation
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Contact
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Email
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Joining Date
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Address
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Status
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-success">Present</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-danger">Absent</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-success">Present</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-danger">Absent</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-success">Present</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-danger">Absent</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-success">Present</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-danger">Absent</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-success">Present</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-danger">Absent</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-success">Present</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td>Caregiver</td>
                          <td>84443934321</td>
                          <td>Rajesh@gmail.com</td>
                          <td>26/06/2024</td>
                          <td>22,tilak appt. surat</td>
                          <td>
                            <span className="text-danger">Absent</span>
                          </td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye.svg" alt="" />
                            </button>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-2.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/printer.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        {/* <!-- Add more rows as needed --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="staff-pagination my-3">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                          <img src="/images/arrow-left-p.svg"/>
                        </a>
                      </li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link p-icon-next" href="#" aria-label="Next">
                        <img src="/images/arrow-left-p.svg"/>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
