import React from "react";
import "./PatientHealth.css";
import Dashboard from "../Dashboard/Dashboard";
import PatientHealthslider from "../Slider/PatientHealthslider/PatientHealthslider";
import { BarChart } from "@mui/x-charts/BarChart";

const pData = [140, 135, 120, 160, 131, 142, 105];
const uData = [89, 64, 75, 85, 73, 68, 55];

const xLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function PatientHealth() {
  return (
    <>
      <Dashboard />
      <div className="main-content patient-health-box">
        <div className="container-fluid">
          <div className="home">
            <div className="breadcrumb-box">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Resident Health Summary</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#">John Doe</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    John Doe Details
                  </li>
                </ol>
              </nav>
            </div>
            <PatientHealthslider />
            <div className="phsd-main">
              <div className="row">
                <div className="col-md-5">
                  <div className="chart-box">
                    <div className="chart-box-top">
                      <h3>Health Metrics Summary</h3>
                      <div className="chart-options-box">
                        <div class="input-field d-flex gap-2">
                          <select>
                            <option value="">BP</option>
                          </select>
                          <select>
                            <option value="">Week</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <BarChart
                      width={500}
                      height={300}
                      series={[
                        { data: pData, label: "systolic pressure", id: "pvId" },
                        {
                          data: uData,
                          label: "diastolic pressure",
                          id: "uvId",
                        },
                      ]}
                      xAxis={[{ data: xLabels, scaleType: "band" }]}
                    />
                  </div>
                  <div className="caretakers-box">
                    <h2 className="mb-3">Caretakers</h2>
                    <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
                      <div className="ct-left d-flex gap-2 align-items-center">
                        <img src="/images/Critical-Alerts-profile.svg" alt=""/>
                        <div className="ct-name">
                          <h4>Ashok Kumar</h4>
                          <p>Son</p>
                        </div>
                      </div>
                      <div className="ct-right d-flex gap-2">
                        <img src="/images/eye-grey.svg" alt=""/>
                        <img src="/images/edit-grey.svg" alt=""/>
                        <img src="/images/trash-grey.svg" alt=""/>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="ct-left d-flex gap-2 align-items-center">
                        <img src="/images/Critical-Alerts-profile.svg" alt=""/>
                        <div className="ct-name">
                          <h4>Ashok Kumar</h4>
                          <p>Son</p>
                        </div>
                      </div>
                      <div className="ct-right d-flex gap-2">
                        <img src="/images/eye-grey.svg" alt=""/>
                        <img src="/images/edit-grey.svg" alt=""/>
                        <img src="/images/trash-grey.svg" alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="caretakers-box">
                    <h2>Past Appointments</h2>
                    <p className="txt-grey mb-3">John doe’s past appointments</p>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div className="ct-left d-flex gap-2 align-items-center">
                        <img src="/images/Critical-Alerts-profile.svg" alt=""/>
                        <div className="ct-name">
                          <h4>Dr.Juliet Gabriel</h4>
                          <p>Cardiologist</p>
                        </div>
                      </div>
                      <div className="ct-right d-flex gap-2">
                        <p>05-Sep-2024, 11:30 am</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="resident">
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                      <h5>Medical Reports</h5>
                      <div className="left-content">
                      <div class="input-field d-flex gap-2">
                          <select>
                            <option value="">Medical Reports</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table-stripedd table table-bordered table-hover align-middle">
                        <thead className="table">
                          <tr>
                            <th scope="col">
                              <div className="checkbox checbox-trans">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                <span
                                  style={{
                                    color: "#fff",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                >
                                  ID
                                </span>{" "}
                                <img
                                  className="updown-arrow"
                                  src="/images/arrow-down-up.svg"
                                  alt=""
                                />
                              </div>
                            </th>
                            <th scope="col">
                              Test Name
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </th>
                            <th scope="col">
                              Date
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </th>
                            <th scope="col">
                              Description
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="checkbox checbox-trans">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                P-002
                              </div>
                            </td>
                            <td>Lab Report</td>
                            <td>12-Jan-2024</td>
                            <td>Glucose Test V12</td>
                            <td className="table-fix">
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>

                              <button
                                className="btn btn-sm"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src="/images/edit-2.svg" alt="" />
                              </button>
                              <ul
                                className="edit-rt dropdown-menu text-center"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="borderbd mx-2">
                                  <a class="dropdown-item" href="#">
                                    Recovered
                                  </a>
                                </li>
                                <li className="mx-2">
                                  <a className="dropdown-item" href="#">
                                    In Treatment
                                  </a>
                                </li>
                              </ul>
                              <button className="btn btn-sm">
                                <img src="/images/printer.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="checkbox checbox-trans">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                P-002
                              </div>
                            </td>
                            <td>Lab Report</td>
                            <td>12-Jan-2024</td>
                            <td>Glucose Test V12</td>
                            <td className="table-fix">
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>

                              <button
                                className="btn btn-sm"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src="/images/edit-2.svg" alt="" />
                              </button>
                              <ul
                                className="edit-rt dropdown-menu text-center"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="borderbd mx-2">
                                  <a class="dropdown-item" href="#">
                                    Recovered
                                  </a>
                                </li>
                                <li className="mx-2">
                                  <a className="dropdown-item" href="#">
                                    In Treatment
                                  </a>
                                </li>
                              </ul>
                              <button className="btn btn-sm">
                                <img src="/images/printer.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="checkbox checbox-trans">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                P-002
                              </div>
                            </td>
                            <td>Lab Report</td>
                            <td>12-Jan-2024</td>
                            <td>Glucose Test V12</td>
                            <td className="table-fix">
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>

                              <button
                                className="btn btn-sm"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src="/images/edit-2.svg" alt="" />
                              </button>
                              <ul
                                className="edit-rt dropdown-menu text-center"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="borderbd mx-2">
                                  <a class="dropdown-item" href="#">
                                    Recovered
                                  </a>
                                </li>
                                <li className="mx-2">
                                  <a className="dropdown-item" href="#">
                                    In Treatment
                                  </a>
                                </li>
                              </ul>
                              <button className="btn btn-sm">
                                <img src="/images/printer.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="checkbox checbox-trans">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                P-002
                              </div>
                            </td>
                            <td>Lab Report</td>
                            <td>12-Jan-2024</td>
                            <td>Glucose Test V12</td>
                            <td className="table-fix">
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>

                              <button
                                className="btn btn-sm"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src="/images/edit-2.svg" alt="" />
                              </button>
                              <ul
                                className="edit-rt dropdown-menu text-center"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="borderbd mx-2">
                                  <a class="dropdown-item" href="#">
                                    Recovered
                                  </a>
                                </li>
                                <li className="mx-2">
                                  <a className="dropdown-item" href="#">
                                    In Treatment
                                  </a>
                                </li>
                              </ul>
                              <button className="btn btn-sm">
                                <img src="/images/printer.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="checkbox checbox-trans">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                P-002
                              </div>
                            </td>
                            <td>Lab Report</td>
                            <td>12-Jan-2024</td>
                            <td>Glucose Test V12</td>
                            <td className="table-fix">
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>

                              <button
                                className="btn btn-sm"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src="/images/edit-2.svg" alt="" />
                              </button>
                              <ul
                                className="edit-rt dropdown-menu text-center"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="borderbd mx-2">
                                  <a class="dropdown-item" href="#">
                                    Recovered
                                  </a>
                                </li>
                                <li className="mx-2">
                                  <a className="dropdown-item" href="#">
                                    In Treatment
                                  </a>
                                </li>
                              </ul>
                              <button className="btn btn-sm">
                                <img src="/images/printer.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="checkbox checbox-trans">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                P-002
                              </div>
                            </td>
                            <td>Lab Report</td>
                            <td>12-Jan-2024</td>
                            <td>Glucose Test V12</td>
                            <td className="table-fix">
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>

                              <button
                                className="btn btn-sm"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src="/images/edit-2.svg" alt="" />
                              </button>
                              <ul
                                className="edit-rt dropdown-menu text-center"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="borderbd mx-2">
                                  <a class="dropdown-item" href="#">
                                    Recovered
                                  </a>
                                </li>
                                <li className="mx-2">
                                  <a className="dropdown-item" href="#">
                                    In Treatment
                                  </a>
                                </li>
                              </ul>
                              <button className="btn btn-sm">
                                <img src="/images/printer.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          {/* <!-- Add more rows as needed --> */}
                        </tbody>
                      </table>
                    </div>
                    <div className="staff-pagination mt-3">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <img src="/images/arrow-left-p.svg" />
                            </a>
                          </li>
                          <li class="page-item active" aria-current="page">
                            <a class="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              4
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              5
                            </a>
                          </li>
                          <li class="page-item">
                            <a
                              class="page-link p-icon-next"
                              href="#"
                              aria-label="Next"
                            >
                              <img src="/images/arrow-left-p.svg" />
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="medication-container">
                        <div className="header">
                          <div>
                            <h2>Medications</h2>
                            <p>Your father’s medication timeline</p>
                          </div>
                          <div>
                            {" "}
                            <a href="#">View all</a>
                          </div>
                        </div>
                        <div className="timeline">
                          <div className="timeline-item">
                            <div className="timeline-inner">
                              <div className="time">09:00am</div>
                              <div className="medication">Fluoxetine 20mg</div>
                            </div>
                            <div className="dose">Morning dose</div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-inner">
                              <div className="time">12:00am</div>
                              <div className="medication">Paracetamol 20mg</div>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-inner">
                              <div className="time">02:00pm</div>
                              <div className="medication">Fluoxetine 20mg</div>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-inner">
                              <div className="time">04:00pm</div>
                              <div className="medication">Fluoxetine 20mg</div>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-inner">
                              <div className="time">08:00pm</div>
                              <div className="medication">Fluoxetine 20mg</div>
                            </div>
                            <div className="dose">Night dose</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="medical-report-inner">
                        <div className="section">
                          <div className="header">
                            <h2>Incident Alerts</h2>
                            <a href="#">View all</a>
                          </div>
                          <div className="alert">
                            <div className="alert-item">
                              <h3>Fall Detection</h3>
                              <p>
                                Mohan's recent walk resulted in a fall, while it
                                wasn’t serious. Further investigation would be
                                required.
                              </p>
                            </div>
                            <div className="alert-item">
                              <h3>Missed Medication</h3>
                              <p>
                                Mohan recently missed his medication in the
                                evening. The caretaker made sure within 30
                                minutes that he had taken it then.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
