import React from 'react';
import './registration.css';
import Dashboard from '../Dashboard/Dashboard';
import ConsentSignatures from './Forms/ConsentSignatures';
import OfficeUseOnly from './Forms/OfficeUseOnly';

export default function Registration() {
    return (
        <>
            <Dashboard />
            <div className='main-content'>
                <div className='container-fluid p-0 m-0'>
                    <div className="home">
                        <div className="col-md-12">
                            <div className="new-reg-form">
                                <h6>New Registration Form</h6>
                                <p>Create a new profile</p>
                                <div className="form-tab">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                data-bs-target="#Personal-Information" type="button" role="tab"
                                                aria-controls="Personal-Information" aria-selected="true">Personal
                                                Information</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="Emergency-Contact-Information-tab" data-bs-toggle="tab"
                                                data-bs-target="#Emergency-Contact-Information" type="button" role="tab"
                                                aria-controls="Emergency-Contact-Information" aria-selected="false">Emergency
                                                Contact Information</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="contact-tab" data-bs-toggle="tab"
                                                data-bs-target="#contact" type="button" role="tab" aria-controls="contact"
                                                aria-selected="false">Detailed
                                                Medical History</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="Functional-Ability-And-ADLs-tab" data-bs-toggle="tab"
                                                data-bs-target="#Functional-Ability-And-ADLs" type="button" role="tab" aria-controls="Functional-Ability-And-ADLs"
                                                aria-selected="false">Functional Ability And ADLs</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="Functional-Ability-five-tab" data-bs-toggle="tab"
                                                data-bs-target="#Functional-Ability-five" type="button" role="tab" aria-controls="Functional-Ability-five"
                                                aria-selected="false">Functional Ability And ADLs</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="Nutritional-needs-tab" data-bs-toggle="tab"
                                                data-bs-target="#Nutritional-needs" type="button" role="tab" aria-controls="Nutritional-needs"
                                                aria-selected="false">Nutritional needs and dietary preference</button>
                                        </li>
                                        <li className="nav-item ms-3" role="presentation">
                                            <button className="nav-link" id="#Sleep-Pattern-and-preferences-tab" data-bs-toggle="tab"
                                                data-bs-target="#Sleep-Pattern-and-preferences" type="button" role="tab" aria-controls="#Sleep-Pattern-and-preferences"
                                                aria-selected="false">Sleep Pattern and preferences</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="#Social-history-and-activities-tab" data-bs-toggle="tab"
                                                data-bs-target="#Social-history-and-activities" type="button" role="tab" aria-controls="#Social-history-and-activities"
                                                aria-selected="false">Social history and activities</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="#Financial-information-tab" data-bs-toggle="tab"
                                                data-bs-target="#Financial-information" type="button" role="tab" aria-controls="#Financial-information"
                                                aria-selected="false">Financial information and payment details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="#Consent-signatures-tab" data-bs-toggle="tab"
                                                data-bs-target="#Consent-signatures" type="button" role="tab" aria-controls="#Consent-signatures"
                                                aria-selected="false">Consent and signatures</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="#office-use-only-tab" data-bs-toggle="tab"
                                                data-bs-target="#office-use-only" type="button" role="tab" aria-controls="#office-use-only"
                                                aria-selected="false">For office use only</button>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="form">
                                <div className="tab-content" id="myTabContent">

                                    <div className="tab-pane fade show active" id="Personal-Information" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <h5>Personal Information</h5>

                                        <div className="forms">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Full Legal Name</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Preferred Name/Nickname</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Date of Birth</label>
                                                        <input type="date" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Age</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">City</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">State</label>
                                                        <select>
                                                            <option value="">Value</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Country</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Gender</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Nationality</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Ethnicity</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Religion/Faith</label>
                                                        <select>
                                                            <option value="">Value</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Primary Language</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Other Languages Spoken</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Education Level</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Occupation(Before Retirement):</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Current Marital Status</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-field">
                                                        <label for="">Spouse's Name</label>
                                                        <input type="text" placeholder="value" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="child">
                                                        <p>Children’s Names and Ages</p>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="1.value" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="col-md-6">
                                                                    <div className="input-field">
                                                                        <input type="text" placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="2.value" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="col-md-6">
                                                                    <div className="input-field">
                                                                        <input type="text" placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="3.value" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="col-md-6">
                                                                    <div className="input-field">
                                                                        <input type="text" placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="residential">
                                                        <h5>Residential Information</h5>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="input-field-two">
                                                                    <label for="">Permanent Address</label>
                                                                    <input type="text" placeholder="Address" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field-two">
                                                                    <label for="">Current Address (If different)</label>
                                                                    <input type="text" placeholder="Address" />

                                                                </div>
                                                                <div className="checkbox">
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <label for="">Same as permanent</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="contact-inforamtion">
                                                        <h5>Contact Information</h5>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Primary Phone Number</label>
                                                                    <input type="text" placeholder="value" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Secondary Phone Number</label>
                                                                    <input type="text" placeholder="value" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Email Address</label>
                                                                    <input type="text" placeholder="value" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <p>Secondary Phone Number</p>
                                                                <div className="checkbox">
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <label for="">Phone</label>
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <label for="">Email</label>
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <label for="">Text Message</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit">Save & Continue</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Emergency-Contact-Information" role="tabpanel"
                                        aria-labelledby="Emergency-Contact-Information-tab">

                                        <h5>Emergency Contact Information</h5>



                                        <div className="forms">
                                            <div className="col-md-12">
                                                <div className="primary-em">
                                                    <ul>
                                                        <li>Primary Emergency Contact</li>
                                                    </ul>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Full Name</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Relationship to Resident:</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Primary phone number</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Secondary phone number</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Email Id</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Address</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>Secondary Emergency Contact</li>
                                                </ul>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Full Name</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Relationship to Resident:</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Primary phone number</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Secondary phone number</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Email Id</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Address</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>Power of Attorney or Legal Guardian Information</li>
                                                </ul>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Full Name</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Relationship to Resident:</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Primary phone number</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Secondary phone number</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Email Id</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Address</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>Financial Power of Attorney (If applicable):</li>
                                                </ul>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Full Name</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Relationship to Resident:</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Primary phone number</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Secondary phone number</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Email Id</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Address</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit">Save & Continue</button>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">

                                        <h5>Detailed Medical History</h5>
                                        <div className="forms">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul>
                                                        <li>Primary Care Physician (PCP) Information</li>
                                                    </ul>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Full Name</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Phone Number</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Email Id</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Clinic Address</label>
                                                                <input type="text" placeholder="value" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Specialists and Other Healthcare Providers</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <button>+ Add</button>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Name</th>
                                                                    <th>Speciality</th>
                                                                    <th>Phone number</th>
                                                                    <th>Address</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>1</td>
                                                                    <td>John Doe</td>
                                                                    <td>Orthopaedic</td>
                                                                    <td>+91-8793437493</td>
                                                                    <td>1st Main Rd, Krishna Reddy Layout, Konappana Agrahara,
                                                                        Electronics City, Bangalore</td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>2</td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig headig-two">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Medical Conditions/Chronic Diseases:</li>
                                                            </ul>
                                                            <span>List all current diagnoses and the date of diagnosis (e.g.,
                                                                Diabetes, 2010):</span>
                                                        </div>
                                                        <div className="add-button">
                                                            <button>+ Add Conditions</button>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Conditions</th>
                                                                    <th>Date Diagnosed</th>
                                                                    <th>Ongoing Treatment</th>
                                                                    <th>Medication</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>1</td>
                                                                    <td>Conditions</td>
                                                                    <td>12-03-2024</td>
                                                                    <td>Treatment is going on for fever</td>
                                                                    <td>Treatment is going on for fever</td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>2</td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Surgical History (Include dates and complications if any)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <button>+ Add</button>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Surgery</th>
                                                                    <th>Date</th>
                                                                    <th>Complications</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>1.</td>
                                                                    <td>Conditions</td>
                                                                    <td>12-03-2024</td>
                                                                    <td>Treatment is going on for fever</td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>
                                                                <tr>

                                                                    <td>2</td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Allergies (List and describe reactions)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <button>+ Add</button>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Medication</th>
                                                                    <th>Food</th>
                                                                    <th>Other</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>2</td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Current Medications</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <button>+ Add</button>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive ">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Medication</th>
                                                                    <th>Dosage</th>
                                                                    <th>Frequency</th>
                                                                    <th>Purpose</th>
                                                                    <th>Acion</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>1.</td>
                                                                    <td>Value</td>
                                                                    <td>Value</td>
                                                                    <td>2 days a time</td>
                                                                    <td>Value</td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>
                                                                <tr>

                                                                    <td>2</td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="vaccination-history">
                                                        <h5>Vaccination History</h5>
                                                        <div className="vaccination-item">
                                                            <div className="vaccination">
                                                                <span>1. Covid 19 Vaccination</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="covid19" value="yes" />
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="covid19" value="no" />
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="covid19_last_dose" />
                                                                </div>
                                                            </div>
                                                            <div className="vaccination">
                                                                <span>2. Influenza (Flu)</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="flu" value="yes" />
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="flu" value="no" />
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="flu_last_dose" />
                                                                </div>
                                                            </div>
                                                            <div className="vaccination">
                                                                <span>3. Pneumonia</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="pneumonia" value="yes" />
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="pneumonia" value="no" />
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="pneumonia_last_dose" />
                                                                </div>
                                                            </div>
                                                            <div className="vaccination">
                                                                <span>4. Tetanus</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="tetanus" value="yes" />
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="tetanus" value="no" />
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="tetanus_last_dose" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Other Treatments or Therapies</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <button>+ Add</button>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Treatment</th>
                                                                    <th>Therapy</th>
                                                                    <th>Frequency</th>
                                                                    <th>Duration</th>
                                                                    <th>Additional Notes</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>1.</td>
                                                                    <td>Value</td>
                                                                    <td>Value</td>
                                                                    <td>2 days a time</td>
                                                                    <td>Value</td>
                                                                    <td>Value</td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>
                                                                <tr>

                                                                    <td>2</td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-field">
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button>
                                                                        <button class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                    </td>

                                                                </tr>


                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <ul>
                                                        <li>Other Treatments or Therapies</li>
                                                    </ul>
                                                    <div className="assistive-devices">

                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="cane" />
                                                                    Cane
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="walker" />
                                                                    Walker
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="wheelchair" />
                                                                    Wheelchair
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="hearing-aid" />
                                                                    Hearing Aid
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="glasses" />
                                                                    Glasses
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <ul>
                                                        <li>Advanced Directives</li>
                                                    </ul>
                                                    <form>
                                                        <fieldset>
                                                            <div className="form-group">
                                                                <label for="dnr">1. Do you have a Do Not Resuscitate (DNR)
                                                                    order?</label>
                                                                <div className="checkbox">
                                                                    <input className="form-check-input" type="checkbox" id="dnr-yes" name="dnr" value="yes" />
                                                                    <label className="form-check-label" for="dnr-yes">Yes</label>
                                                                    <input className='form-check-input' type="checkbox" id="dnr-no" name="dnr" value="no" />
                                                                    <label className="form-check-label" for="dnr-no">No</label>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label for="living-will">2. Living Will</label>
                                                                <div className="checkbox">
                                                                    <input className='form-check-input' type="checkbox" id="living-will-yes" name="living-will" value="yes" />
                                                                    <label className='form-check-label' for="living-will-yes">Yes</label>
                                                                    <input className='form-check-input' type="checkbox" id="living-will-no" name="living-will" value="no" />
                                                                    <label className='form-check-label' for="living-will-no">No</label>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label for="medical-power">3. Medical Power of Attorney</label>
                                                                <div className="checkbox">
                                                                    <input className='form-check-input' type="checkbox" id="medical-power-yes" name="medical-power" value="yes" />
                                                                    <label className='form-check-label' for="medical-power-yes">Yes</label>
                                                                    <input className='form-check-input' type="checkbox" id="medical-power-no" name="medical-power" value="no" />
                                                                    <label className='form-check-label' for="medical-power-no">No</label>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </form>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="input-field">
                                                                <label for="">Full Name</label>
                                                                <input type="text" name="" id="" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="input-field">
                                                                <label for="">Relationship</label>
                                                                <input type="text" name="" id="" placeholder="value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="input-field" >
                                                                <label for="">Contact Info</label>
                                                                <input type="text" name="" id="" placeholder="value" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button>Save & Continue</button>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Functional-Ability-And-ADLs" role="tabpanel" aria-labelledby="Functional-Ability-And-ADLs-tab">
                                        <h5 className="w-auto px-2">Functional Ability And ADLs (Activities of Daily Living)</h5>
                                        <div className="forms">
                                            <form className="adls-form">
                                                <fieldset className="">
                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Mobility Assessment</li>
                                                        </ul>

                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-independent" value="fully-independent" />
                                                            <label className="form-check-label" for="fully-independent">
                                                                Fully Independent
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="minimal-assistance" value="minimal-assistance" />
                                                            <label className="form-check-label" for="minimal-assistance">
                                                                Requires minimal assistance (explain)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="moderate-assistance" value="moderate-assistance" />
                                                            <label className="form-check-label" for="moderate-assistance">
                                                                Requires moderate assistance (explain)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-dependent" value="fully-dependent" />
                                                            <label className="form-check-label" for="fully-dependent">
                                                                Fully dependent
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Walking aids used</li>
                                                        </ul>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="none" value="none" />
                                                            <label className="form-check-label" for="none">
                                                                None
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="cane" value="cane" />
                                                            <label className="form-check-label" for="cane">
                                                                Cane
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="walker" value="walker" />
                                                            <label className="form-check-label" for="walker">
                                                                Walker
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="wheelchair" value="wheelchair" />
                                                            <label className="form-check-label" for="wheelchair">
                                                                Wheelchair
                                                            </label>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="other" value="other" />
                                                            <div className="last-dose d-flex align-items-center ms-2">
                                                                <label for="other">
                                                                    Other
                                                                </label>
                                                                <input type="text" className="" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Appointment Scheduled with Physiotherapist</li>
                                                        </ul>
                                                        <div className="d-flex">
                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-yes" value="yes" />
                                                            <label className="form-check-label ms-1 me-2" for="appointment-yes">
                                                                Yes
                                                            </label>

                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-no" value="no" />
                                                            <label className="form-check-label ms-1 me-2" for="appointment-no">
                                                                No
                                                            </label>

                                                        </div>
                                                    </div>

                                                    <button type="submit">Save & Continue</button>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Functional-Ability-five" role="tabpanel" aria-labelledby="Functional-Ability-five-tab">
                                        <h5>Functional Ability And ADLs (Activities of Daily Living)</h5>
                                        <div className="forms Functional">
                                            <form className="adls-form">
                                                <fieldset className="">
                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Mobility Assessment</li>
                                                        </ul>

                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-independent" value="fully-independent" />
                                                            <label className="form-check-label" for="fully-independent">
                                                                Fully Independent
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="minimal-assistance" value="minimal-assistance" />
                                                            <label className="form-check-label" for="minimal-assistance">
                                                                Requires minimal assistance (explain)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="moderate-assistance" value="moderate-assistance" />
                                                            <label className="form-check-label" for="moderate-assistance">
                                                                Requires moderate assistance (explain)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-dependent" value="fully-dependent" />
                                                            <label className="form-check-label" for="fully-dependent">
                                                                Fully dependent
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Walking aids used</li>
                                                        </ul>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="none" value="none" />
                                                            <label className="form-check-label" for="none">
                                                                None
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="cane" value="cane" />
                                                            <label className="form-check-label" for="cane">
                                                                Cane
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="walker" value="walker" />
                                                            <label className="form-check-label" for="walker">
                                                                Walker
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="wheelchair" value="wheelchair" />
                                                            <label className="form-check-label" for="wheelchair">
                                                                Wheelchair
                                                            </label>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="other" value="other" />
                                                            <div className="last-dose d-flex align-items-center ms-2">
                                                                <label for="other">
                                                                    Other
                                                                </label>
                                                                <input type="text" className="" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Appointment Scheduled with Physiotherapist</li>
                                                        </ul>
                                                        <div className="d-flex">
                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-yes" value="yes" />
                                                            <label className="form-check-label ms-1 me-2" for="appointment-yes">
                                                                Yes
                                                            </label>

                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-no" value="no" />
                                                            <label className="form-check-label ms-1 me-2" for="appointment-no">
                                                                No
                                                            </label>

                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                        <div className="Mobility">
                                            <h5>Mobility Assessment</h5>
                                            <div className="forms pt-0">
                                                <div className="General-Mobility">
                                                    <p>1. General Mobility Status</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Current Mobility Level (Check all that apply)</label>
                                                        </li>
                                                        <div className="General-Mobility-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Fully-Independent" id="Fully-Independent" value="Fully-Independent" />
                                                                <label className="form-check-label" for="Fully-Independent">
                                                                    Fully Independent (Able to move freely without assistance)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" />
                                                                <label className="form-check-label" for="Independent">
                                                                    Independent with Aids (Uses a cane, walker, or other assistive devices independently)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Minimal" id="Needs-Minimal" value="Needs-Minimal" />
                                                                <label className="form-check-label" for="Needs-Minimal">
                                                                    Needs Minimal Assistance (Occasional help with balance, walking, or transfers)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Moderate" id="Needs-Moderate" value="Needs-Moderate" />
                                                                <label className="form-check-label" for="Needs-Moderate">
                                                                    Needs Moderate Assistance (Requires help with transfers and walking, but can bear some weight)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Full" id="Needs-Full" value="Needs-Full" />
                                                                <label className="form-check-label" for="Needs-Full">
                                                                    Needs Full Assistance (Dependent on caregivers for transfers and movement)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Non-ambulatory" id="Non-ambulatory" value="Non-ambulatory" />
                                                                <label className="form-check-label" for="Non-ambulatory">
                                                                    Non-ambulatory (Unable to walk or transfer without full assistance)
                                                                </label>
                                                            </div>
                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>Additional Notes on Mobility Status:</label>
                                                                <div className="last-dose w-md-50">
                                                                    <input type="text" className="" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                </div>
                                                <div className="Gait-Balance mt-3">
                                                    <p>2. Gait and Balance</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Gait Pattern (How the resident walks)</label>
                                                        </li>
                                                        <div className="Gait-Balance-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Normal" id="Normal" value="Normal" />
                                                                <label className="form-check-label" for="Normal">
                                                                    Normal
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Shuffling" id="Shuffling" value="Shuffling" />
                                                                <label className="form-check-label" for="Shuffling">
                                                                    Shuffling (small steps, difficulty lifting feet)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Unsteady" id="Unsteady" value="Unsteady" />
                                                                <label className="form-check-label" for="Unsteady">
                                                                    Unsteady (risk of falling, requires supervision)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Hemiplegic" id="Hemiplegic" value="Hemiplegic" />
                                                                <label className="form-check-label" for="Hemiplegic">
                                                                    Hemiplegic (paralysis/weakness on one side)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Ataxic" id="Ataxic" value="Ataxic" />
                                                                <label className="form-check-label" for="Ataxic">
                                                                    Ataxic (uncoordinated, staggering movements)
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <input class="form-check-input" type="checkbox" name="walking-aids" id="other" value="other" />
                                                                <div class="last-dose d-flex align-items-center ms-2">
                                                                    <label for="other">Other</label>
                                                                    <input type="text" class="" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Balance">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Balance</label>
                                                            </li>
                                                            <div className="Gait-Balance-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Stable" id="Stable" value="Stable" />
                                                                    <label className="form-check-label" for="Stable">
                                                                        Stable (No issues with balance)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Occasional" id="Occasional" value="Occasional" />
                                                                    <label className="form-check-label" for="Occasional">
                                                                        Occasional Imbalance (Needs support at times)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Frequent" id="Frequent" value="Frequent" />
                                                                    <label className="form-check-label" for="Frequent">
                                                                        Frequent Imbalance (Requires close supervision)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Falls" id="Falls" value="Falls" />
                                                                    <label className="form-check-label" for="Falls">
                                                                        Falls Frequently
                                                                    </label>
                                                                </div>
                                                                <div class="d-flex align-items-center mt-2">
                                                                    <label className="form-check-label" for="other">Notes on Balance Issues:</label>
                                                                    <input type="text" class="border-0 border-bottom border-dark" />
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Strength mt-3">
                                                    <p>3. Strength and Endurance</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Leg Strength</label>
                                                        </li>
                                                        <div className="Strength-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Normal" id="Normal" value="Normal" />
                                                                <label className="form-check-label" for="Normal">
                                                                    Normal
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Weakness-in-One-Leg" id="Weakness-in-One-Leg" value="Weakness-in-One-Leg" />
                                                                <label className="form-check-label" for="Shuffling">
                                                                    Weakness in One Leg
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Specify:</label>
                                                                    <input type="text" className="w-md-25" />
                                                                </div>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Weakness-in-Both-Legs" id="Weakness-in-Both-Legs" value="Weakness-in-Both-Legs" />
                                                                <label className="form-check-label" for="Weakness-in-Both-Legs">
                                                                    Weakness in Both Legs
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Paralysis" id="Paralysis" value="Paralysis" />
                                                                <label className="form-check-label" for="Paralysis">
                                                                    Paralysis in One or Both Legs
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Arm-Strength">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Arm Strength</label>
                                                            </li>
                                                            <div className="Arm-Strength-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Normal" id="Normal" value="Normal" />
                                                                    <label className="form-check-label" for="Normal">
                                                                        Normal
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Weakness-in-One-Arm" id="Weakness-in-One-Arm" value="Weakness-in-One-Arm" />
                                                                    <label className="form-check-label" for="Weakness-in-One-Ar">
                                                                        Weakness in One Arm
                                                                    </label>
                                                                    <div class="last-dose d-flex">
                                                                        <label for="other">Specify:</label>
                                                                        <input type="text" className="w-md-25" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Weakness-in-Both-Arm" id="Weakness-in-Both-Arm" value="Weakness-in-Both-Arm" />
                                                                    <label className="form-check-label" for="Weakness-in-Both-Arm">
                                                                        Weakness in Both Arm
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Paralysis" id="Paralysis" value="Paralysis" />
                                                                    <label className="form-check-label" for="Paralysis">
                                                                        Paralysis in One or Both Arm
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>

                                                    <div className="Endurance">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Endurance</label>
                                                            </li>
                                                            <div className="Endurance-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="High" id="High" value="High" />
                                                                    <label className="form-check-label" for="High">
                                                                        High (Able to walk long distances without fatigue)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Moderate" id="Moderate" value="Moderate" />
                                                                    <label className="form-check-label" for="Moderate">
                                                                        Moderate (Able to walk short distances but fatigues easily)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Low" id="Low" value="Low" />
                                                                    <label className="form-check-label" for="Low">
                                                                        Low (Limited endurance, needs frequent rest breaks)
                                                                    </label>
                                                                </div>
                                                                <div class=" d-flex align-items-center mt-2">
                                                                    <label className="form-check-label" for="other">Notes on Balance Issues:</label>
                                                                    <input type="text" class="border-0 border-bottom border-dark" />
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>

                                                </div>
                                                <div className="Falls-history mt-3">
                                                    <p>4. Falls History</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Has the Resident Experienced Falls in the Past Year?</label>
                                                        </li>
                                                        <div className="Falls-history-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Yes" id="Yes" value="Yes" />
                                                                <label className="form-check-label" for="Yes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="No" id="No" value="No" />
                                                                <label className="form-check-label" for="No">
                                                                    No
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Many-Falls" id="Many-Falls" value="Many-Falls" />
                                                                <label className="form-check-label" for="Many-Falls">
                                                                    If Yes, How Many Falls?
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Specify:</label>
                                                                    <input type="text" className="w-md-25" />
                                                                </div>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Circumstances" id="Circumstances" value="Circumstances" />
                                                                <label className="form-check-label" for="Circumstances">
                                                                    Circumstances of Falls (e.g., location, time of day, activities leading to falls)
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Specify:</label>
                                                                    <input type="text" className="w-md-25" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Injury">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Injury from Falls</label>
                                                            </li>
                                                            <div className="Injury-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="No-Injuries" id="No-Injuries" value="No-Injuries" />
                                                                    <label className="form-check-label" for="No-Injuries">
                                                                        No Injuries
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Minor" id="Minor" value="Minor" />
                                                                    <label className="form-check-label" for="Minor">
                                                                        Minor Injuries (e.g., bruises, scrapes)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Major" id="Major" value="Major" />
                                                                    <label className="form-check-label" for="Major">
                                                                        Major Injuries (e.g., fractures, head injury)
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Details" id="Weakness-in-One-Arm" value="Details" />
                                                                    <label className="form-check-label" for="Details">
                                                                        Details on Injuries from Falls
                                                                    </label>
                                                                    <div class="last-dose d-flex">
                                                                        <label for="other">Specify:</label>
                                                                        <input type="text" className="w-md-25" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </ul>
                                                    </div>

                                                    <div className="Current-fall">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Current Fall Prevention Strategies in Place (Check all that apply)</label>
                                                            </li>
                                                            <div className="Current-fall-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Bed" id="Bed" value="Bed" />
                                                                    <label className="form-check-label" for="Bed">
                                                                        Bed/Chair Alarms
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Non-Slip" id="Non-Slip" value="Non-Slip" />
                                                                    <label className="form-check-label" for="Non-Slip">
                                                                        Non-Slip Footwear
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Grab-Bars" id="Grab-Bars" value="Grab-Bars" />
                                                                    <label className="form-check-label" for="Grab-Bars">
                                                                        Grab Bars in Bathroom/Shower
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Increased" id="Increased" value="Increased" />
                                                                    <label className="form-check-label" for="Increased">
                                                                        Increased Supervision/Monitoring
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Hip" id="Hip" value="Hip" />
                                                                    <label className="form-check-label" for="Hip">
                                                                        Hip Protectors
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" class="border-0 border-bottom border-dark" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>

                                                </div>
                                                <div className="Falls-history mt-3">
                                                    <p>5. Transfer Abilities (Bed, Chair, Toilet)</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Ability to Transfer (Moving from one position to another):</label>
                                                        </li>
                                                        <div className="Falls-history-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Fully-Independent" id="Fully-Independent" value="Fully-Independent" />
                                                                <label className="form-check-label" for="Fully-Independent">
                                                                    Fully Independent (Transfers without assistance)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs" id="Needs" value="Needs" />
                                                                <label className="form-check-label" for="Needs">
                                                                    Needs Supervision (Able to transfer independently but requires supervision)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Minimal" id="Needs-Minimal" value="Needs-Minimal" />
                                                                <label className="form-check-label" for="Needs-Minimal">
                                                                    Needs Minimal Assistance (Requires occasional help with transfers)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Moderate" id="Needs-Moderate" value="Needs-Moderate" />
                                                                <label className="form-check-label" for="Needs-Moderate">
                                                                    Needs Moderate Assistance (Requires frequent help, but can bear some weight)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Full" id="Needs-Full" value="Needs-Full" />
                                                                <label className="form-check-label" for="Needs-Full">
                                                                    Needs-Full Assistance (Dependent on caregiver for all transfers)
                                                                </label>
                                                            </div>
                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>Notes on Endurance Levels:</label>
                                                                <div class="last-dose w-md-50">
                                                                    <input type="text" className="" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Preferred-Transfer">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Preferred Transfer Methods (e.g., pivot transfer, use of lift)</label>
                                                            </li>
                                                            <div className="Preferred-Transfer-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Stand-Pivot" id="Stand-Pivot" value="Stand-Pivot" />
                                                                    <label className="form-check-label" for="Stand-Pivot">
                                                                        Stand-Pivot Transfer (Assistance standing and turning)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Sliding-Board" id="Sliding-Board" value="Sliding-Board" />
                                                                    <label className="form-check-label" for="Sliding-Board">
                                                                        Sliding Board (Helps slide from one surface to another)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Mechanical" id="Mechanical" value="Mechanical" />
                                                                    <label className="form-check-label" for="Mechanical">
                                                                        Mechanical Lift (Hoyer lift, ceiling lift)
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Two-Person" id="Two-Person" value="Two-Person" />
                                                                    <label className="form-check-label" for="Two-Person">
                                                                        Two-Person Transfer (Requires two caregivers for safe transfer)
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" class="border-0 border-bottom border-dark" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Assistive-Devices mt-3">
                                                    <p>6. Use of Assistive Devices</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Current Assistive Devices Used (Check all that apply):</label>
                                                        </li>
                                                        <div className="Assistive-Devices-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Cane" id="Cane" value="Cane" />
                                                                <label className="form-check-label" for="Cane">
                                                                    Cane
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Walker" id="Walker" value="Walkero" />
                                                                <label className="form-check-label" for="No">
                                                                    Walker
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Scooter" id="Scooter" value="Scooter" />
                                                                <label className="form-check-label" for="Scooter">
                                                                    Scooter
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Crutches" id="Crutches" value="Crutches" />
                                                                <label className="form-check-label" for="Crutches">
                                                                    Crutches
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Orthotic-Braces" id="Orthotic-Braces" value="Orthotic-Braces" />
                                                                <label className="form-check-label" for="Orthotic-Braces">
                                                                    Orthotic Braces
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Type:</label>
                                                                    <input type="text" className="w-25" />
                                                                </div>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Prosthetics" id="Prosthetics" value="Prosthetics" />
                                                                <label className="form-check-label" for="Prosthetics">
                                                                    Prosthetics
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Type:</label>
                                                                    <input type="text" className="w-25" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Effectiveness">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Effectiveness of Assistive Devices</label>
                                                            </li>
                                                            <div className="Effectiveness-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Very-Effective" id="Very-Effective" value="Very-Effective" />
                                                                    <label className="form-check-label" for="Very-Effective">
                                                                        Very Effective (Resident can use devices independently)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Moderately" id="Moderately" value="Moderately" />
                                                                    <label className="form-check-label" for="Moderately">
                                                                        Moderately Effective (Occasional difficulty using devices)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Ineffective" id="Ineffective" value="Ineffective" />
                                                                    <label className="form-check-label" for="Ineffective">
                                                                        Ineffective (Needs supervision/assistance while using devices)
                                                                    </label>
                                                                </div>

                                                                <div className="d-md-flex align-item-ceter mt-2">
                                                                    <label>Notes on Endurance Levels:</label>
                                                                    <div className="last-dose w-md-50">
                                                                        <input type="text" className="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Bed-Mobility mt-3">
                                                    <p>7. Specialized Mobility Support Needs</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Bed Mobility</label>
                                                        </li>
                                                        <div className="Bed-Mobility-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" />
                                                                <label className="form-check-label" for="Independent">
                                                                    Independent (Able to turn and reposition without help)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Supervision" id="Needs-Supervision" value="Needs-Supervision" />
                                                                <label className="form-check-label" for="No">
                                                                    Needs Supervision (Able to move with supervision)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Assistance" id="Needs-Assistance" value="Needs-Assistance" />
                                                                <label className="form-check-label" for="Needs-Assistance">
                                                                    Needs Assistance (Requires assistance to turn and reposition)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Fully-Dependent" id="Fully-Dependent" value="Fully-Dependent" />
                                                                <label className="form-check-label" for="Fully-Dependent">
                                                                    Fully Dependent (Unable to move without full assistance)
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Wheelchair">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Wheelchair Use</label>
                                                            </li>
                                                            <div className="Wheelchair-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="self-Propelled" id="self-Propelled" value="self-Propelled" />
                                                                    <label className="form-check-label" for="self-Propelled">
                                                                        Self-Propelled (Able to use wheelchair independently)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Requires-Assistance" id="Requires-Assistance" value="Requires-Assistance" />
                                                                    <label className="form-check-label" for="Requires-Assistance">
                                                                        Requires Assistance (Needs help to propel or navigate wheelchair)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Fully-Dependent" id="Fully-Dependent" value="Fully-Dependent" />
                                                                    <label className="form-check-label" for="Fully-Dependent">
                                                                        Fully Dependent (Unable to use wheelchair without full assistance)
                                                                    </label>
                                                                </div>

                                                                <div className="d-md-flex align-item-ceter mt-2">
                                                                    <label>Notes on Wheelchair Use (e.g., posture support, cushions):</label>
                                                                    <div className="last-dose w-md-50">
                                                                        <input type="text" className="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Bed-Chair">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Bed/Chair Alarms :</label>
                                                            </li>
                                                            <div className="Bed-Chair-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Not-Used" id="Not-Used" value="Not-Used" />
                                                                    <label className="form-check-label" for="Not-Used">
                                                                        Not Used
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Used-Occasionally" id="Used-Occasionally" value="Used-Occasionally" />
                                                                    <label className="form-check-label" for="Used-Occasionally">
                                                                        Used Occasionally (For safety during specific times)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Used-Regularly" id="Used-Regularly" value="Used-Regularly" />
                                                                    <label className="form-check-label" for="Used-Regularly">
                                                                        Used Regularly (For consistent monitoring and fall prevention)
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Positioning-Devices">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Positioning Devices :</label>
                                                            </li>
                                                            <div className="Positioning-Devices">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Not-Needed" id="Not-Needed" value="Not-Needed" />
                                                                    <label className="form-check-label" for="Not-Needed">
                                                                        Not Needed
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Special-Cushions" id="Special-Cushions" value="Special-Cushions" />
                                                                    <label className="form-check-label" for="Special-Cushions">
                                                                        Special Cushions (e.g., for pressure relief)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Wedges" id="Wedges" value="Wedges" />
                                                                    <label className="form-check-label" for="Wedges">
                                                                        Wedges (e.g., to help with positioning in bed or chair)
                                                                    </label>
                                                                </div>

                                                                <div class="d-flex align-item-center">
                                                                    <input class="form-check-input" type="checkbox" />
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label class="form-check-label" for="other">Other</label>
                                                                        <input type="text" class="border-0 border-bottom border-dark" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Presence mt-3">
                                                    <p>8. Pain Management Related to Mobility</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Presence of Pain During Movement:</label>
                                                        </li>
                                                        <div className="Presence-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="No-Pain" id="No-Pain" value="No-Pain" />
                                                                <label className="form-check-label" for="No-Pain">
                                                                    No Pain
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Mild-Pain" id="Mild-Pain" value="Mild-Pain" />
                                                                <label className="form-check-label" for="Mild-Pain">
                                                                    Mild Pain (Occasional discomfort during movement)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Moderate-Pain" id="Moderate-Paine" value="Moderate-Pain" />
                                                                <label className="form-check-label" for="Moderate-Pain">
                                                                    Moderate Pain (Regular discomfort, managed with interventions)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Severe-Pain" id=" Severe-Pain" value="Severe-Pain" />
                                                                <label className="form-check-label" for=" Severe-Pain">
                                                                    Severe Pain (Requires regular pain management during movement)
                                                                </label>
                                                            </div>
                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>Location of Pain (e.g., knees, back, hips):</label>
                                                                <div className="last-dose w-md-50">
                                                                    <input type="text" className="" />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </ul>
                                                    <div className="Pain-Management">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Pain Management Interventions (Check all that apply):</label>
                                                            </li>
                                                            <div className="Pain-Management-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Medications" id="Medications" value="Medications" />
                                                                    <label className="form-check-label" for="Medications">
                                                                        Medications
                                                                    </label>
                                                                    <div className="last-dose d-flex align-item-center">
                                                                        <label>Type and Dosage:</label>
                                                                        <div className="last-dose w-md-50">
                                                                            <input type="text" className="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Physical-Therapy" id="Physical-Therapy" value="Physical-Therapy" />
                                                                    <label className="form-check-label" for="Physical-Therapy">
                                                                        Physical Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Massage-Therapy" id="Massage-Therapy" value="Massage-Therapy" />
                                                                    <label className="form-check-label" for="Massage-Therapy">
                                                                    Massage Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Hot-Cold-Therapy" id="Hot-Cold-Therapy" value="Hot-Cold-Therapy" />
                                                                    <label className="form-check-label" for="Hot-Cold-Therapy">
                                                                        Hot/Cold Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" className="border-0 border-bottom border-dark" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Current mt-3">
                                                    <p>9. Pain Management Related to Mobility</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Current Physical Therapy :</label>
                                                        </li>
                                                        <div className="Current-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Yes" id="Yes" value="Yes" />
                                                                <label className="form-check-label" for="Yes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="No" id="No" value="No" />
                                                                <label className="form-check-label" for="Mild-Pain">
                                                                    No
                                                                </label>
                                                            </div>

                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>If Yes, Frequency:</label>
                                                                <div className="last-dose w-md-50">
                                                                    <input type="text" className="" />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </ul>
                                                    <div className="Pain-Management">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Pain Management Interventions (Check all that apply):</label>
                                                            </li>
                                                            <div className="Pain-Management-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Medications" id="Medications" value="Medications" />
                                                                    <label className="form-check-label" for="Medications">
                                                                        Medications
                                                                    </label>
                                                                    <div className="last-dose d-flex align-item-center">
                                                                        <label>Type and Dosage:</label>
                                                                        <div className="last-dose w-md-50">
                                                                            <input type="text" className="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Physical-Therapy" id="Physical-Therapy" value="Physical-Therapy" />
                                                                    <label className="form-check-label" for="Physical-Therapy">
                                                                        Physical Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Massage-Therapy" id="Massage-Therapy" value="Massage-Therapy" />
                                                                    <label className="form-check-label" for="Massage-Therapy">
                                                                    Massage Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Hot-Cold-Therapy" id="Hot-Cold-Therapy" value="Hot-Cold-Therapy" />
                                                                    <label className="form-check-label" for="Hot-Cold-Therapy">
                                                                        Hot/Cold Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" className="border-0 border-bottom border-dark" />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-md-6">
                                                                        <div class="input-field">
                                                                            <label for="">Therapist Name</label>
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div class="input-field">
                                                                            <label for="">Contact Info</label>
                                                                            <input type="text" placeholder="value" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Goals">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Goals of Therapy:</label>
                                                            </li>
                                                            <div className="Goals-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Strength-Building" id="Strength-Building" value="Strength-Building" />
                                                                    <label className="form-check-label" for="Strength-Building">
                                                                        Strength Building
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Flexibility" id="Flexibility" value="Flexibility" />
                                                                    <label className="form-check-label" for="Flexibility">
                                                                        Flexibility and Range of Motion
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Gait-Improvement" id="Gait-Improvement" value="Gait-Improvement" />
                                                                    <label className="form-check-label" for="Gait-Improvement">
                                                                        Gait Improvement
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Pain-Reduction" id="Pain-Reduction" value="Pain-Reduction" />
                                                                    <label className="form-check-label" for="Pain-Reduction">
                                                                        Pain Reduction
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Fall-Prevention" id="Fall-Prevention" value="Fall-Prevention" />
                                                                    <label className="form-check-label" for="Fall-Prevention">
                                                                        Fall Prevention
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" />
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" className="border-0 border-bottom border-dark" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Current mt-3">
                                                    <p>10. Additional Notes on Mobility</p>
                                                    <ul className="ps-5 pb-4 border-bottom">
                                                        <li className="mb-2">
                                                            <label htmlFor="" className="d-block">Specific Concerns or Needs Related to Mobility (e.g., exercises, limitations, preferences)</label>
                                                            <div className="last-dose mb-0">
                                                                <input className="border-none " type="text" placeholder="Value"/>
                                                            </div>
                                                        </li>


                                                    </ul>
                                                    <div className="Self-Care border-bottom pb-4">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Self-Care Abilities</label>
                                                            </li>
                                                            <div className="Self-Care-inner">
                                                                <div className="Bathing">
                                                                    <span>1. Bathing</span>

                                                                    <div className="bathing-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" />
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" />
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" />
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" />
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions/Preferences</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Dressing mt-2">
                                                                    <span>2. Dressing</span>

                                                                    <div className="Dressing-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" />
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" />
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" />
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" />
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions/Preferences</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Eating mt-2">
                                                                    <span>3. Eating</span>

                                                                    <div className="Eating-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" />
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" />
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" />
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" />
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions (e.g., soft foods, assistance cutting food)</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Grooming mt-2">
                                                                    <span>4. Grooming</span>

                                                                    <div className="Grooming-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" />
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" />
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" />
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" />
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions/Preferences</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Continence border-bottom py-3">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Continence</label>
                                                            </li>

                                                            <span>1. Bladder Control</span>
                                                            <div className="Goals-inner mt-2">

                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Fully-Continent" id="Fully-Continent" value="Fully-Continent" />
                                                                    <label className="form-check-label" for="Fully-Continent">
                                                                        Fully Continent
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Occasional" id="Occasional" value="Occasional" />
                                                                    <label className="form-check-label" for="Occasional">
                                                                        Occasional Incontinence
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Gait-Improvement" id="Gait-Improvement" value="Gait-Improvement" />
                                                                    <label className="form-check-label" for="Gait-Improvement">
                                                                        Full Incontinence
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Fall-Prevention" id="Fall-Prevention" value="Fall-Prevention" />
                                                                    <label className="form-check-label" for="Fall-Prevention">
                                                                        Uses (Check all that apply)
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <div className="form-check me-2">
                                                                        <input className="form-check-input" type="radio" name="Pads" id="Pads" value="Pads" />
                                                                        <label className="form-check-label" for="Pads">
                                                                            Pads
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check me-2">
                                                                        <input className="form-check-input" type="radio" name=" Ostomy-Bag" id=" Ostomy-Bag" value=" Ostomy-Bag" />
                                                                        <label className="form-check-label" for=" Ostomy-Bag">
                                                                            Ostomy Bag
                                                                        </label>
                                                                    </div>
                                                                    <div class="d-flex align-item-center">
                                                                        <input class="form-check-input" type="radio" />
                                                                        <div class=" d-flex align-items-center ms-2">
                                                                            <label class="form-check-label" for="other">Other</label>
                                                                            <input type="text" class="border-0 border-bottom border-dark" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Cognitive pt-3">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Cognitive Function</label>
                                                            </li>
                                                            <div className="Cognitive-inner">
                                                                <div className="Memory-Issues">
                                                                    <span>1. Memory Issues</span>

                                                                    <div className="Memory-Issues-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="None" id="None" value="None" />
                                                                            <label className="form-check-label" for="None">
                                                                                None
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Mild" id="Mild" value="Mild" />
                                                                            <label className="form-check-label" for="Mild">
                                                                                Mild
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Moderate" id="Moderate" value="Moderate" />
                                                                            <label className="form-check-label" for="Moderate">
                                                                                Moderate
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Severe" id="Severe" value="Severe" />
                                                                            <label className="form-check-label" for="Severe">
                                                                                Severe
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Orientation mt-2">
                                                                    <span>2. Orientation</span>

                                                                    <div className="Orientation-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Fully-Oriented" id="Fully-Oriented" value="Fully-Oriented" />
                                                                            <label className="form-check-label" for="Fully-Oriented">
                                                                                Fully Oriented
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Occasionally" id="Occasionally" value="Occasionally" />
                                                                            <label className="form-check-label" for="Occasionally">
                                                                                Occasionally Disoriented
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Always-Disoriented" id="Always-Disoriented" value="Always-Disoriented" />
                                                                            <label className="form-check-label" for="Always-Disoriented">
                                                                                Always Disoriented
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Eating mt-2">
                                                                    <span>3. Decision-Making Ability</span>

                                                                    <div className="Eating-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" />
                                                                            <label className="form-check-label" for="Independent">
                                                                                Fully Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-Cueing" id="Needs-Cueing" value="Needs-Cueing" />
                                                                            <label className="form-check-label" for="Needs-Cueing">
                                                                                Needs Cueing
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Dependent" id="Dependent" value="Dependent" />
                                                                            <label className="form-check-label" for="Dependent">
                                                                                Dependent
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" />
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Notes on Cognitive Function</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <button typeof="submit">Save & Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Nutritional-needs" role="tabpanel" aria-labelledby="Nutritional-needs-tab">
                                        <h5>Nutritional needs and dietary preference</h5>
                                        <div className="forms">
                                            <div className="">
                                                <form className="">
                                                    <fieldset className="">


                                                        <div className="mb-4">
                                                            <ul>
                                                                <li>Dietary Preferences</li>
                                                            </ul>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="vegetarian" value="vegetarian" />
                                                                <label className="form-check-label" for="vegetarian">
                                                                    Vegetarian
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="non-vegetarian" value="non-vegetarian" />
                                                                <label className="form-check-label" for="non-vegetarian">
                                                                    Non-Vegetarian
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="vegan" value="vegan" />
                                                                <label className="form-check-label" for="vegan">
                                                                    Vegan
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="other" value="other" />
                                                                <label className="form-check-label ms-1" for="other">
                                                                    Other
                                                                </label>
                                                                <div className="last-dose d-flex align-items-center">

                                                                    <input type="text" className="" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            <ul>
                                                                <li>Specific Dietary Requirements/Restrictions</li>
                                                            </ul>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="diabetic" value="diabetic" />
                                                                <label className="form-check-label" for="diabetic">Diabetic</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="low-sodium" value="low-sodium" />
                                                                <label className="form-check-label" for="low-sodium">Low Sodium</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="low-fat" value="low-fat" />
                                                                <label className="form-check-label" for="low-fat">Low Fat</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="high-protein" value="high-protein" />
                                                                <label className="form-check-label" for="high-protein">High Protein</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="soft-diet" value="soft-diet" />
                                                                <label className="form-check-label" for="soft-diet">Soft Diet</label>
                                                            </div>
                                                            <div className="d-md-flex align-items-center">
                                                                <div className="d-flex">
                                                                    <input className="form-check-input" type="checkbox" id="other-diet-restriction" value="other-diet-restriction" />
                                                                    <label className="form-check-label ms-1" for="other-diet-restriction">Other Restrictions (please explain)</label>
                                                                </div>
                                                                <div className="last-dose d-flex align-items-center ms-1 w-sm-100">
                                                                    <input type="text" className="w-sm-10" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            <ul className="d-flex">
                                                                <li className="">
                                                                    <label className="form-label">Food Allergies</label>

                                                                </li>
                                                                <li className="list-group w-50">
                                                                    <div className="last-dose d-flex align-items-center">

                                                                        <input type="text" className="w-100" />
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className="d-md-flex">
                                                                <li className="">
                                                                    <label className="form-label">Special Notes Regarding Food (e.g., preferences, dislikes, mealtime routines)</label>

                                                                </li>
                                                                <li className="list-group w-lg-25">
                                                                    <div className="last-dose d-flex align-items-center">

                                                                        <input type="text" className=" w-100" />
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                        </div>

                                                        <div className="mb-1">
                                                            <ul>
                                                                <li>Hydration Preferences</li>
                                                            </ul>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="normal-fluid-intake" value="normal-fluid-intake" />
                                                                <label className="form-check-label" for="normal-fluid-intake">Normal Fluid Intake</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Requires-Encouragement" id="Requires-Encouragement" value="Requires-Encouragement" />
                                                                <label className="form-check-label" for="Requires-Encouragement">Requires Encouragement</label>
                                                            </div>

                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="low-fluid" value="low-fluid" />
                                                                <label className="form-check-label" for="low-fluid">Low Fluid</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="high-protein-fluid" value="high-protein-fluid" />
                                                                <label className="form-check-label" for="high-protein-fluid">High Protein</label>

                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="soft-diet-fluid" value="soft-diet-fluid" />
                                                                <label className="form-check-label" for="soft-diet-fluid">Soft Diet</label>
                                                            </div>
                                                            <div className="d-md-flex align-items-center">
                                                                <div className="d-flex">
                                                                    <input className="form-check-input" type="checkbox" name="hydration-preference" id="restricted" value="restricted" />
                                                                    <label className="form-check-label ms-1" for="restricted">Restricted Fluid Intake(Reason)</label>
                                                                </div>
                                                                <div className="last-dose d-flex align-items-center ms-1 w-sm-100">
                                                                    <input type="text" className="w-sm-10" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="btn btn-primary">Save & Continue</button>
                                                    </fieldset>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Sleep-Pattern-and-preferences" role="tabpanel" aria-labelledby="Sleep-Pattern-and-preferences-tab">
                                        <h5>Sleep Pattern and preferences</h5>
                                        <div className="forms ps-0">
                                            <p>1. Typical Sleep Routine</p>
                                            <ul className="d-flex ps-5">
                                                <li className="">
                                                    <label className="form-label">Bedtime</label>

                                                </li>
                                                <li className="list-group">
                                                    <div className="last-dose d-flex align-items-center">

                                                        <input type="text" className=" border-bottom-5" />
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="d-flex ps-5">
                                                <li className="">
                                                    <label className="form-label">Preferred Wake time</label>

                                                </li>
                                                <li className="list-group w-50">
                                                    <div className="last-dose d-flex align-items-center">

                                                        <input type="text" className=" border-bottom-5" />
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="d-md-flex ps-5">
                                                <li className=" w-md-25">
                                                    <input className="form-check-input" type="checkbox" name="Nap-Habits" id="Nap-Habits" value="Nap-Habits" />
                                                    <label className="form-check-label ms-1" for="Nap-Habits">Nap Habits</label>

                                                </li>
                                                <li className=" ms-md-4 w-md-50 d-flex align-item-center" style={{ listStyle: "none" }}>
                                                    <input className="form-check-input" type="checkbox" name="Other-Restrictions" id="Other-Restrictions" value="Other-Restrictions" />
                                                    <label className="form-check-label ms-1" for="Other-Restrictions">Other Restrictions (please explain):</label>
                                                </li>
                                                <li className="list-group w-lg-50 w-md-25">
                                                    <div className="last-dose d-flex align-items-center">

                                                        <input type="text" className="border-bottom-5" />
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="my-4">
                                                <p>2. Sleep Disturbances</p>
                                                <div className="ps-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="None" id="None" value="None" />
                                                        <label className="form-check-label" for="None">None</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Insomnia" id="Insomnia" value="Insomnia" />
                                                        <label className="form-check-label" for="Insomnia">Insomnia</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Sleep-Apnea" id="Sleep-Apnea" value="Sleep-Apnea" />
                                                        <label className="form-check-label" for="Sleep-Apnea">Sleep Apnea</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Nightmares" id="Nightmares" value="Nightmares" />
                                                        <label className="form-check-label" for="Nightmares">Nightmares</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Other" id="Other" value="Other" />
                                                        <label className="form-check-label" for="Other">Other</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="my-4">
                                                <p>3. Sleep Aids</p>
                                                <div className="ps-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="None" id="None" value="None" />
                                                        <label className="form-check-label" for="None">None</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="CPAP" id="CPAP" value="CPAP" />
                                                        <label className="form-check-label" for="CPAP">CPAP</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Medications" id="Medications" value="Medications" />
                                                        <label className="form-check-label" for="Medications">Medications</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my4">
                                                <div className="d-md-flex align-item-ceter">
                                                    <p>4.Special Notes Regarding Food (e.g., preferences, dislikes, mealtime routines)</p>
                                                    <div className="last-dose d-flex align-items-center w-md-50">

                                                        <input type="text" className="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Social-history-and-activities" role="tabpanel" aria-labelledby="Social-history-and-activities-tab">
                                        <h5>Social Preferences</h5>
                                        <div className="forms ps-0">
                                            <div className="">
                                                <p>1. Group Activities</p>
                                                <div className="ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="yes" id="yes" value="yes" />
                                                        <label className="form-check-label" for="yes">Yes</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="No" id="No" value="No" />
                                                        <label className="form-check-label" for="None">No</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Sometimes" id="Sometimes" value="Sometimes" />
                                                        <label className="form-check-label" for="Sometimes">Sometimes</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Nightmares" id="Nightmares" value="Nightmares" />
                                                        <label className="form-check-label" for="Nightmares">Nightmares</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="my-4">
                                                <p>2.Preferred Activities (Check all that apply)</p>
                                                <div className="ps-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Reading" id="Reading" value="Reading" />
                                                        <label className="form-check-label" for="Reading">Reading</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Music" id="Music" value="Music" />
                                                        <label className="form-check-label" for="Music">Music</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Gardening" id="Gardening" value="Gardening" />
                                                        <label className="form-check-label" for="Gardening">Gardening</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Board-Card-Games" id="Board-Card-Games" value="Board-Card-Games" />
                                                        <label className="form-check-label" for="Board-Card-Games">Board/Card Games</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Other" id="Walking" value="Walking" />
                                                        <label className="form-check-label" for="Walking">Walking</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Yoga" id="Yoga" value="Yoga" />
                                                        <label className="form-check-label" for="Yoga">Yoga/Exercise</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Religious-Services" id="Religious-Services" value="Religious-Services" />
                                                        <label className="form-check-label" for="Religious-Services">Religious Services</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Crafts" id="Crafts" value="Crafts" />
                                                        <label className="form-check-label" for="Crafts">Crafts</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Other" id="Other" value="Other" />
                                                        <label className="form-check-label" for="Other">Other </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <p>3. Hobbies and Interests</p>
                                            </div>

                                            <div className="mt-3 d-md-flex">
                                                <div className="">
                                                    <p>4. Previous Community Involvement (e.g., clubs, organizations)</p>
                                                </div>
                                                <div className="last-dose w-50">
                                                    <input type="text" className="border-bottom-5 w-100" />
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <p>5.Preferred Activities (Check all that apply)</p>
                                                <ul className="d-flex ps-5">
                                                    <li>
                                                        <label>Preferred Faith-Based Activities</label>
                                                    </li>
                                                    <div className="last-dose w-50">
                                                        <input type="text" className="border-bottom-5 w-100" />
                                                    </div>
                                                </ul>
                                                <ul className="d-flex ps-5">
                                                    <li>
                                                        <label>Special Observances</label>
                                                    </li>
                                                    <div className="last-dose w-50">
                                                        <input type="text" className="border-bottom-5 w-100" />
                                                    </div>
                                                </ul>
                                            </div>
                                            <div className="mt-3">
                                                <p>6.Family Visitation Preferences</p>

                                                <ul className="ps-5">
                                                    <li>
                                                        <label>Preferred Faith-Based Activities</label>
                                                    </li>
                                                </ul>
                                                <div className=" mt-0 ps-4 mb-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="None" id="None" value="None" />
                                                        <label className="form-check-label" for="Other">None </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Weekly" id="Weekly" value="Weekly" />
                                                        <label className="form-check-label" for="Weekly">Weekly </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Monthly" id="Monthly" value="Monthly" />
                                                        <label className="form-check-label" for="Monthly">Monthly </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Special-Occasions-Only" id="Special-Occasions-Only" value="Special-Occasions-Only" />
                                                        <label className="form-check-label" for="Special-Occasions-Only">Special Occasions Only </label>
                                                    </div>
                                                </div>
                                                <ul className="ps-5">
                                                    <li>
                                                        <label>Best Times for Visits Last Dose :</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Financial-information" role="tabpanel" aria-labelledby="Financial-information-tab">
                                        <h5>Social Preferences</h5>
                                        <div className="forms ps-0">
                                            <p>1. Responsible Party for Billing</p>
                                            <div className="Responsible ps-4">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Full Name</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Relationship to Resident</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Phone Number</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Email Address</label>
                                                            <input type="text" placeholder="value" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-4">
                                                <p>2. Preferred Activities (Check all that apply)</p>
                                                <div className="ps-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Reading" id="Reading" value="Reading" />
                                                        <label className="form-check-label" for="Reading">Reading</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Music" id="Music" value="Music" />
                                                        <label className="form-check-label" for="Music">Music</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Gardening" id="Gardening" value="Gardening" />
                                                        <label className="form-check-label" for="Gardening">Gardening</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Board-Card-Games" id="Board-Card-Games" value="Board-Card-Games" />
                                                        <label className="form-check-label" for="Board-Card-Games">Board/Card Games</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Other" id="Walking" value="Walking" />
                                                        <label className="form-check-label" for="Walking">Walking</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Yoga" id="Yoga" value="Yoga" />
                                                        <label className="form-check-label" for="Yoga">Yoga/Exercise</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Religious-Services" id="Religious-Services" value="Religious-Services" />
                                                        <label className="form-check-label" for="Religious-Services">Religious Services</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Crafts" id="Crafts" value="Crafts" />
                                                        <label className="form-check-label" for="Crafts">Crafts</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Other" id="Other" value="Other" />
                                                        <label className="form-check-label" for="Other">Other </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ConsentSignatures/>
                                    <OfficeUseOnly/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}