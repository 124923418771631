import React from 'react';
import './Signup.css';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";

export default function FamilySignup() {
    const navigate = useNavigate();
    return (
        <div className="sign-up">
        <div className="container-lg">
            <div className="row">
            <div className="col-md-6">
                    {/* <div className="sign-up-image">
                        <div className="sign-up-overlay">
                            <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div> */}
                    <div className='left-img position-relative'>
                        <img src={LoginImg} alt='left img'/>
                        <div className='left-img-text'>
                        <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="sign-up-section">
                        <div className="sign-up-wellcome mb-0">
                            <img src={Logo} alt=""/>
                            <h3>Sign Up</h3>
                            <p>Enter your details below to create your account and get started.</p>
                        </div>
                        <div className="sign-up-field">
                            <div className="input-field">
                                <label for="">First Name*</label>
                                <input type="text" placeholder="Enter your name"/>
                            </div>
                            <div className="input-field">
                                <label for="">Email*</label>
                                <input type="text" placeholder="Enter your email id"/>
                            </div>
                            <div className="input-field">
                                <label for="">Phone Number*</label>
                                <input type="text" placeholder="Enter your Number"/>
                            </div>
                            <div className="input-field mt-3">
                                <button type="submit">Send OTP</button>
                            </div>
                            <span>Already have an account?<a href='' onClick={() => navigate('/family/login')}> Login</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      );
}
